import { useEffect } from 'react';
import { useState } from 'react';
import { Avatar, Box, Chip, FormHelperText, FormLabel, Grid, IconButton, InputAdornment, InputBase, InputLabel, Typography } from '@material-ui/core';

import { DialogContents, Dialogs, DialogTitles, GreenButton, SecondaryButton, SelectBox, TextBox } from '../../../Core/FormInput';
import { fade, makeStyles, useTheme } from '@material-ui/core/styles';

import {
    getBookingRequest,
    getCommunicatorBookingRequestScreenDropDown,
    postBookingAccept,
    postBookingCancel,
    postBookingRequested,
    postBookingReSchedule,
    postJobProgressSetVehicleArrivedStatus
} from '../../../Core/Service/communicator-service';
import { useContext } from 'react';
import AppContext from '../../../App/AppContext';
import { singleEmailValidation } from '../../../Core/Service/StaticService';
import { CustomeSignPad } from '../../../Core/SignaturePad';
import formatters from '../../../Core/Grid/inputFormatter';
import { getDataByRegNo } from '../../../Core/Service/common-service';
import SearchIcon from '@material-ui/icons/Search';
import { CustomIconButton } from '../../../Core/FormInput/AppButton';
import CloseIcon from '@material-ui/icons/Close';
import BuildIcon from '@material-ui/icons/Build';
import RequestBookingsHistoryModal from './RequestBookingsHistory';

const BookingRequestedModal = (props) => {
    const { showToast, userRoles } = useContext(AppContext);
    const isCustomer = userRoles.includes('customer');
    const isInternal = userRoles.includes('internal');
    const [state, setState] = useState({
        isSetDisabled: true,
        branchList: [],
        errors: {},
        isEditMode: false,
        isValueChanged: false,
        isCustomerUpdate: false,
        isUpdateSms: false
    });

    const pullDataByRegNo = async () => {
        let res = await getDataByRegNo(state.registration);
        let data = res.data.mot;
        setState((st) => ({
            ...st,
            dateRequested: data.dateRequested,
            make: data.make,
            model: data.model,
            registrationDate: data.registrationDate,
            motExpiryDate: data.motExpiryDate
        }));
    };

    useEffect(async () => {
        let getByIdData = {};
        let resDrop = await getCommunicatorBookingRequestScreenDropDown();
        if (props.selectedRecordData?.bookingRequestID) {
            let res = await getBookingRequest(props.selectedRecordData.bookingRequestID);
            let data = res.data.list[0];
            let historyData = res.data.history || [];
            getByIdData = {
                dateRequested: data.dateRequested,
                make: data.make,
                model: data.model,
                branchID: data.branchID,
                jobDetail: data.jobDetail,
                jobNotes: data.jobNotes,
                registration: data.registration,
                date: data.bookingDate,
                isEditMode: true,
                bookingRequestStatusID: data.bookingRequestStatusID,
                isCanceled: data.bookingRequestStatusID == 5,
                historyList: historyData
            };
        }
        setState((st) => ({ ...st, branchList: resDrop.data.branches, ...getByIdData }));
    }, []);

    const handleInput = (e) => {
        const { name, value, type, checked } = e.target;
        setState((st) => {
            let newSt = { ...st };
            if (type == 'checkbox') {
                newSt[name] = checked;
            } else {
                newSt[name] = value;
            }
            newSt.isValueChanged = true;
            if (name == 'registration') {
                if (newSt.registration.length > 4) {
                    newSt.isSetDisabled = false;
                } else {
                    newSt.isSetDisabled = true;
                }
            }

            return newSt;
        });
    };
    const handleSeprateStatusInput = (e) => {
        const { name, value, type, checked } = e.target;
        setState((st) => {
            let newSt = { ...st };
            newSt[name] = value;
            return newSt;
        });
    };

    const validation = () => {
        let { dateRequested, branchID, jobDetail, isCustomerUpdate, isCustomerEmail, isUpdateSms, isPhoneNo } = state;
        let isValid = true;
        let errors = {};

        if (!dateRequested) {
            isValid = false;
            errors.dateRequested = 'Date Requested Is Required';
        }
        if (!branchID) {
            isValid = false;
            errors.branchID = 'Please Select Branch';
        }
        if (!jobDetail) {
            isValid = false;
            errors.jobDetail = 'Job Details Is Required';
        }
        // if (isCustomerUpdate && !singleEmailValidation(isCustomerEmail)) {
        //     isValid = false;
        //     errors.isCustomerEmail = 'Please Add Customer Email';
        // }
        // if (isUpdateSms && !isPhoneNo) {
        //     isValid = false;
        //     errors.isPhoneNo = 'Please Add Customer Email';
        // }

        setState((st) => ({ ...st, errors: errors }));
        return isValid;
    };

    const reScheduleValidation = () => {
        let { date } = state;
        let isValid = true;
        let errors = {};

        if (!date) {
            isValid = false;
            errors.date = 'Re-Schedule Date Is Required';
        }

        setState((st) => ({ ...st, errors: errors }));
        return isValid;
    };

    const cancleValidation = () => {
        let { cancelReason } = state;
        let isValid = true;
        let errors = {};

        if (!cancelReason) {
            isValid = false;
            errors.cancelReason = 'Cancel Reason Is Required';
        }

        setState((st) => ({ ...st, errors: errors }));
        return isValid;
    };

    const handleSubmit = async (isClose) => {
        if (validation()) {
            let data = {
                id: props.selectedRecordData?.bookingRequestID || null,
                branchID: state.branchID,
                dateRequested: state.dateRequested,
                registration: state.registration,
                make: state.make,
                model: state.model,
                jobDetail: state.jobDetail,
                jobNotes: state.jobNotes,
                isCustomerEmail: state.isCustomerEmail || '',
                isPhoneNo: state.isPhoneNo || ''
            };

            let res = await postBookingRequested(data);
            if (res.success) {
                state.isEditMode ? showToast(`Details Updated Successfully`) : showToast(`Booking Requested Successfully`);
                isClose && props.onClose(true);
            } else {
                state.isEditMode ? showToast(`Details Failed To Add`) : showToast(`Booking Requested Failed To Add`);
            }
            return true;
        } else {
            return false;
        }
    };

    const handleBookingAcceptSubmit = async () => {
        let data = {
            id: props.selectedRecordData.bookingRequestID,
            isCustomerEmail: state.isCustomerEmail || '',
            isPhoneNo: state.isPhoneNo || ''
        };
        if (state.isValueChanged) {
            let res = await handleSubmit(false);
            if (!res) {
                return false;
            }
        }

        let res = await postBookingAccept(data);
        if (res.success) {
            showToast(`Booking Accepted Successfully`);
            props.onClose(true);
        } else {
            showToast(`Booking Accept Failed To Add`);
        }
    };

    const handleBookingCancelSubmit = async () => {
        if (cancleValidation()) {
            let data = {
                cancelReason: state.cancelReason,
                id: props.selectedRecordData.bookingRequestID,
                isCustomerEmail: state.isCustomerEmail || '',
                isPhoneNo: state.isPhoneNo || ''
            };
            if (state.isValueChanged) {
                let res = await handleSubmit(false);
                if (!res) {
                    return false;
                }
            }
            let res = await postBookingCancel(data);
            if (res.success) {
                showToast(`Booking Cancel Successfully`);
                props.onClose(true);
            } else {
                showToast(`Booking failed to cancel`);
            }
        }
    };

    const handleBookingReScheduleSubmit = async () => {
        if (reScheduleValidation()) {
            let data = {
                date: state.date,
                id: props.selectedRecordData.bookingRequestID,
                isCustomerEmail: state.isCustomerEmail || '',
                isPhoneNo: state.isPhoneNo || ''
            };
            if (state.isValueChanged) {
                let res = await handleSubmit(false);
                if (!res) {
                    return false;
                }
            }
            let res = await postBookingReSchedule(data);
            if (res.success) {
                showToast(`Re-Schedule Added Successfully`);
                props.onClose(true);
            } else {
                showToast(`Booking ReSchedule failed to add`);
            }
        }
    };

    const handleHistoryModal = (val) => {
        setState((st) => ({ ...st, history: true }));
    };
    const closeHistoryModal = (val) => {
        setState((st) => ({ ...st, history: false }));
    };

    const requestBookingsHeaderComp = (ModalTitle) => {
        return (
            <Grid container>
                <Grid item>
                    <span>
                        {`Bookings ${props.selectedRecordData?.status ? `(${props.selectedRecordData.status})` : ''}`}
                        {props.isShowHistory && (
                            <Chip
                                style={{ marginLeft: 10 }}
                                avatar={
                                    <Avatar>
                                        <BuildIcon />
                                    </Avatar>
                                }
                                label="History"
                                onClick={handleHistoryModal}
                            />
                        )}
                    </span>
                </Grid>
            </Grid>
        );
    };

    return (
        <Dialogs open={true} onClose={() => props.onClose(false)} maxWidth="md">
            <Box display="flex" alignItems="center">
                <Box flexGrow={1}>{<DialogTitles>{requestBookingsHeaderComp()}</DialogTitles>}</Box>
                <Box>
                    <IconButton onClick={() => props.onClose(false)}>
                        <CloseIcon />
                    </IconButton>
                </Box>
            </Box>
            <DialogContents className="hiddenScroll">
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Grid container spacing={2} alignItems="flex-end">
                            {state.registrationDate && state.motExpiryDate && (
                                <>
                                    <Grid item xs={6}>
                                        Registration Date:&nbsp; {formatters.MonthShortFormatter(state.registrationDate)}
                                    </Grid>
                                    <Grid item xs={6}>
                                        MOT Expiry Date:&nbsp; {formatters.MonthShortFormatter(state.motExpiryDate)}
                                    </Grid>
                                </>
                            )}
                            <Grid item xs={6}>
                                <Typography color="secondary" required>
                                    Reg Number
                                </Typography>
                                <TextBox
                                    placeholder="Reg Number"
                                    type="text"
                                    name="registration"
                                    variant="outlined"
                                    fullWidth
                                    value={state.registration}
                                    onChange={handleInput}
                                    disabled={state.isEditMode}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <CustomIconButton toolTipTitle="Get Vehicle Details" onClick={pullDataByRegNo} disabled={state.isEditMode}>
                                                    <SearchIcon />
                                                </CustomIconButton>
                                            </InputAdornment>
                                        )
                                    }}
                                />
                                <FormHelperText error>{state.errors.registration}</FormHelperText>
                            </Grid>
                            <Grid item xs={6} style={{ paddingBottom: '15px' }}>
                                {/* <InputLabel >Branches</InputLabel> */}
                                <FormLabel component="legend" required className="mandatoryfields">
                                    Vehicle Location
                                </FormLabel>
                                <SelectBox List={state.branchList} name="branchID" value={+state.branchID} onChange={handleInput} insertEmpty={false} />
                                <FormHelperText error>{state.errors.branchID}</FormHelperText>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <Grid container spacing={2}>
                            <Grid item xs={6} sm={6}>
                                <FormLabel component="legend">Make</FormLabel>
                                <TextBox autoFocus name="make" onChange={handleInput} value={state.make} />
                            </Grid>
                            <Grid item xs={6} sm={6}>
                                <FormLabel component="legend">Model</FormLabel>
                                <TextBox autoFocus name="model" onChange={handleInput} value={state.model} />
                            </Grid>
                            <Grid item xs={6} sm={6}>
                                <FormLabel component="legend" required className="mandatoryfields">
                                    Date Requested
                                </FormLabel>
                                <TextBox
                                    type="datetime-local"
                                    name="dateRequested"
                                    value={state.dateRequested}
                                    variant="outlined"
                                    fullWidth
                                    onChange={handleInput}
                                />
                                <FormHelperText error>{state.errors.dateRequested}</FormHelperText>
                            </Grid>
                            {state.isEditMode && (
                                <Grid item xs={6} sm={6}>
                                    <FormLabel component="legend">Re-Schedule Date</FormLabel>
                                    <TextBox
                                        type="datetime-local"
                                        name="date"
                                        value={state.date}
                                        variant="outlined"
                                        fullWidth
                                        onChange={handleSeprateStatusInput}
                                    />
                                    <FormHelperText error>{state.errors.date}</FormHelperText>
                                </Grid>
                            )}
                            <Grid item xs={12}>
                                <FormLabel component="legend" required className="mandatoryfields">
                                    Job Detail
                                </FormLabel>
                                <TextBox multiline rows={3} autoFocus name="jobDetail" onChange={handleInput} value={state.jobDetail} />
                                <FormHelperText error>{state.errors.jobDetail}</FormHelperText>
                            </Grid>
                            <Grid item xs={12}>
                                <FormLabel component="legend">Job Notes</FormLabel>
                                <TextBox multiline rows={3} autoFocus name="jobNotes" onChange={handleInput} value={state.jobNotes} />
                            </Grid>
                            {state.isEditMode && (
                                <Grid item xs={12}>
                                    <FormLabel component="legend">Cancel Reason</FormLabel>
                                    <TextBox multiline rows={3} autoFocus name="cancelReason" onChange={handleSeprateStatusInput} value={state.cancelReason} />
                                    <FormHelperText error>{state.errors.cancelReason}</FormHelperText>
                                </Grid>
                            )}
                            {/* <Grid item xs={12} md={8}>
                                <InputLabel shrink>
                                    <input
                                        type="checkbox"
                                        style={{ cursor: 'pointer' }}
                                        name="isCustomerUpdate"
                                        onChange={handleInput}
                                        checked={state.isCustomerUpdate}
                                    />
                                    Customer Email
                                </InputLabel>
                                <TextBox
                                    placeholder="Email update to customer"
                                    type="email"
                                    name="isCustomerEmail"
                                    variant="outlined"
                                    fullWidth
                                    value={state.isCustomerEmail}
                                    onChange={handleInput}
                                    error={state.isCustomerUpdate}
                                />
                                <FormHelperText error>{state.errors.isCustomerEmail}</FormHelperText>
                            </Grid>
                            <Grid item xs={12} md={4}>
                                <InputLabel shrink>
                                    <input
                                        type="checkbox"
                                        style={{ cursor: 'pointer' }}
                                        onChange={handleInput}
                                        name="isUpdateSms"
                                        checked={state.isUpdateSms}
                                    />
                                    Phone Number
                                </InputLabel>
                                <TextBox
                                    placeholder="Phone Number"
                                    type="tel"
                                    name="isPhoneNo"
                                    variant="outlined"
                                    fullWidth
                                    value={state.isPhoneNo}
                                    onChange={handleInput}
                                    error={state.isUpdateSms}
                                />
                                <FormHelperText error>{state.errors.isPhoneNo}</FormHelperText>
                            </Grid> */}
                        </Grid>
                    </Grid>

                    {state.isEditMode ? (
                        <Grid item xs={12}>
                            <Grid container spacing={2}>
                                <Grid item xs={6} lg={3}>
                                    <SecondaryButton className="Submitbtn" fullWidth onClick={() => handleSubmit(true)} disabled={state.isCanceled}>
                                        Save
                                    </SecondaryButton>
                                </Grid>
                                {state.bookingRequestStatusID !== 4 ||
                                (isCustomer && state.bookingRequestStatusID == 2) ||
                                (isInternal && (state.bookingRequestStatusID == 1 || state.bookingRequestStatusID == 3)) ? (
                                    <Grid item xs={6} lg={3}>
                                        <SecondaryButton className="Submitbtn" fullWidth onClick={handleBookingAcceptSubmit} disabled={state.isCanceled}>
                                            Accept
                                        </SecondaryButton>
                                    </Grid>
                                ) : null}
                                <Grid item xs={6} lg={3}>
                                    <SecondaryButton className="Submitbtn" fullWidth onClick={handleBookingReScheduleSubmit} disabled={state.isCanceled}>
                                        ReSchedule
                                    </SecondaryButton>
                                </Grid>
                                <Grid item xs={6} lg={3}>
                                    <SecondaryButton className="Submitbtn" fullWidth onClick={handleBookingCancelSubmit} disabled={state.isCanceled}>
                                        Cancel Booking
                                    </SecondaryButton>
                                </Grid>
                            </Grid>
                        </Grid>
                    ) : (
                        <Grid item xs={12}>
                            <SecondaryButton className="Submitbtn" fullWidth onClick={() => handleSubmit(true)} disabled={state.isCanceled}>
                                Submit
                            </SecondaryButton>
                        </Grid>
                    )}
                </Grid>
            </DialogContents>
            {state.history ? (
                <RequestBookingsHistoryModal
                    onClose={() => closeHistoryModal(false)}
                    messaegList={state.historyList}
                    registration={state.registration}
                    make={state.make}
                    model={state.model}
                />
            ) : null}
        </Dialogs>
    );
};

export default BookingRequestedModal;
