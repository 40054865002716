const storage = localStorage;

const getName = () => storage.getItem('_displayName_');
const getToken = () => storage.getItem('_token_');
const getLastLogin = () => storage.getItem('_lastLogin_');
const getCanResetPassword = () => (storage.getItem('_canResetPassword_') || '').toUpperCase() === 'TRUE';
const getCanOverrideMandatorySteps = () => (storage.getItem('_canOverrideMandatorySteps_') || '').toUpperCase() === 'TRUE';
const getCanAssignJobsToAuditor = () => (storage.getItem('_canAssignJobsToAuditor_') || '').toUpperCase() === 'TRUE';
const getUserRoles = () => (storage.getItem('_userRoles_') || '').split(',');
const getCanReturnJobsFromLastWorkflow = () => (storage.getItem('_canReturnJobsFromLastWorkflow_') || '').toUpperCase() === 'TRUE';
const getCanDeleteDocuments = () => (storage.getItem('_canDeleteDocuments_') || '').toUpperCase() === 'TRUE';

const getSignature = () => {
    let sign = storage.getItem('_user_sign_');
    return sign == 'absent' ? null : sign;
};
const setSignature = (sign) => {
    sign && storage.setItem('_user_sign_', sign);
};
const getSignatureStatus = () => {
    return storage.getItem('_signature_status_');
};
const getWIPAllowed = () => {
    return (storage.getItem('_eWIP_Allowed_') || '').toUpperCase() === 'TRUE';
};
const getSpecialEquipmentAllowed = () => {
    return (storage.getItem('_specialEquipmentAllowed_') || '').toUpperCase() === 'TRUE';
};
const getCommunicatorAllowed = () => {
    return (storage.getItem('_eCommunicator_Allowed_') || '').toUpperCase() === 'TRUE';
};
const getInvoiceAllowed = () => {
    return (storage.getItem('_eInvoice_Allowed_') || '').toUpperCase() === 'TRUE';
};
const getReportingAllowed = () => {
    return (storage.getItem('_eReporting_Allowed_') || '').toUpperCase() === 'TRUE';
};

const menuPermissionCounter = () => {
    return getWIPAllowed() + getCommunicatorAllowed() + getInvoiceAllowed() + getReportingAllowed();
};

const getReportingAgedMeasureAllowed = () => {
    return (storage.getItem('_isAgedMeasure_Allowed_') || '').toUpperCase() === 'TRUE';
};
const getReportingBudgetDashboardAllowed = () => {
    return (storage.getItem('_isBudgetDashboard_Allowed_') || '').toUpperCase() === 'TRUE';
};
const getReportingDOCAllowed = () => {
    return (storage.getItem('_isDOC_Allowed_') || '').toUpperCase() === 'TRUE';
};
const getReportingKPIsAllowed = () => {
    return (storage.getItem('_isKPIs_Allowed_') || '').toUpperCase() === 'TRUE';
};
const getReportingPartsAllowed = () => {
    return (storage.getItem('_isParts_Allowed_') || '').toUpperCase() === 'TRUE';
};
const getReportingSalesAllowed = () => {
    return (storage.getItem('_isSales_Allowed_') || '').toUpperCase() === 'TRUE';
};
const getReportingServiceAllowed = () => {
    return (storage.getItem('_isService_Allowed_') || '').toUpperCase() === 'TRUE';
};
const getReportingWorkShopAllowed = () => {
    return (storage.getItem('_isWorkShop_Allowed_') || '').toUpperCase() === 'TRUE';
};

const getCanAssignTechnicianAllowed = () => {
    return (storage.getItem('_canAssignTechnician_') || '').toUpperCase() === 'TRUE';
};

const setSignatureStatus = (ID) => {
    ID && storage.setItem('_signature_status_', ID);
};

const getSignNotifiedStatus = () => {
    return storage.getItem('_signature_notified_') || 0;
};

const setSignNotifiedStatus = (ID) => {
    ID && storage.setItem('_signature_notified_', ID);
};
const getPortalSetting = () => {
    const setting = storage.getItem('_portal_setting_');
    if (setting) {
        return JSON.parse(setting);
    }
    return setting;
};
const setPortalSetting = (setting) => {
    storage.setItem('_portal_setting_', JSON.stringify(setting));
};

export const AppStorage = {
    getItem: storage.getItem,
    setItem: storage.setItem,
    removeItem: storage.removeItem,
    getName,
    getToken,
    getLastLogin,
    getCanOverrideMandatorySteps,
    getCanResetPassword,
    getCanReturnJobsFromLastWorkflow,
    getCanDeleteDocuments,
    getCanAssignJobsToAuditor,
    getUserRoles,
    getPortalSetting,
    setPortalSetting,
    getSignature,
    setSignature,
    getSignatureStatus,
    setSignatureStatus,
    setSignNotifiedStatus,
    getSignNotifiedStatus,
    getCommunicatorAllowed,
    getInvoiceAllowed,
    getReportingAllowed,
    getWIPAllowed,
    getSpecialEquipmentAllowed,
    menuPermissionCounter,
    getReportingAgedMeasureAllowed,
    getReportingBudgetDashboardAllowed,
    getReportingDOCAllowed,
    getReportingKPIsAllowed,
    getReportingPartsAllowed,
    getReportingSalesAllowed,
    getReportingServiceAllowed,
    getReportingWorkShopAllowed,
    getCanAssignTechnicianAllowed,
    isLoggedIn: () => !!getName() && !!getToken(),
    performLogin: ({ data }) => {
        storage.setItem('_token_', data.token);
        storage.setItem('_displayName_', data.displayName);
        storage.setItem('_lastLogin_', data.lastLogin);
        storage.setItem('_canOverrideMandatorySteps_', data.canOverrideMandatorySteps);
        storage.setItem('_canResetPassword_', data.canResetPassword);
        storage.setItem('_canAssignJobsToAuditor_', data.canAssignJobsToAuditor);
        storage.setItem('_userRoles_', data.roles);
        storage.setItem('_canReturnJobsFromLastWorkflow_', data.canReturnJobsFromLastWorkflow);
        storage.setItem('_canDeleteDocuments_', data.canDeleteDocuments);
        storage.setItem('_signature_status_', data.signatureStatus);
        storage.setItem('_user_sign_', data.defaultSignature || 'absent');
        storage.setItem('_eWIP_Allowed_', data.eWIPAllowed);
        storage.setItem('_specialEquipmentAllowed_', data.specialEquipmentAllowed);
        storage.setItem('_eCommunicator_Allowed_', data.eCommunicatorAllowed);
        storage.setItem('_eInvoice_Allowed_', data.eInvoiceAllowed);
        storage.setItem('_eReporting_Allowed_', data.eReportingAllowed);
        storage.setItem('_isAgedMeasure_Allowed_', data.isAgedMeasure);
        storage.setItem('_isBudgetDashboard_Allowed_', data.isBudgetDashboard);
        storage.setItem('_isDOC_Allowed_', data.isDOC);
        storage.setItem('_isKPIs_Allowed_', data.isKPIs);
        storage.setItem('_isParts_Allowed_', data.isParts);
        storage.setItem('_isSales_Allowed_', data.isSales);
        storage.setItem('_isService_Allowed_', data.isService);
        storage.setItem('_isWorkShop_Allowed_', data.isWorkShop);
        storage.setItem('_canAssignTechnician_', data.canAssignTechnician);
    },
    performLogout: () => {
        const portalSetting = getPortalSetting();
        storage.clear();

        if (portalSetting) {
            setPortalSetting({ ...portalSetting, expired: true });
        }
    }
};
