import { Grid, Tooltip } from '@material-ui/core';
import { Assignment, Edit } from '@material-ui/icons';
import React, { useEffect, useMemo, useState } from 'react';
import ReactDOM from 'react-dom';
import BreadCrumbs from '../../../Core/Controls/Breadcrumb';
import { SecondaryButton } from '../../../Core/FormInput';
import { SquareButton } from '../../../Core/FormInput/AppButton';
import DialogComp from '../../../Core/Controls/Dialog/DialogComp';
import ReportGijgoGrid from '../../internal/ReportsScreens/GijgoForReportsScreen';
import AddEditSpecialEquipment from './AddEditSpecialEquipment';
import Barcode from 'react-barcode/lib/react-barcode';
import { History } from '@material-ui/icons';
import SpecialEquipmentHistoryModal from './HistoryModal';
import { useHistory } from 'react-router-dom';

const SpecialEquipment = (props) => {
    const [state, setState] = useState({
        showAddEdit: false,
        showHistory: false,
        showUsage: false
    });

    const crumbs = () => [
        { name: 'Home', path: '/' },
        { name: 'Special Equipments', active: true },
    ];

    const baseColumns = [
        {
            field: 'action',
            sortable: false,
            title: `Action`,
            width: '5%',
            filterable: false
        },
        {
            field: 'specialEquipmentID',
            title: 'ID',
            flex: 1,
            sortable: false,
            hidden: true
        },
        {
            field: 'specialEquipmentDescription',
            title: 'Description',
            flex: 1
        },
        {
            field: 'barcode',
            title: 'Barcode',
            width: '10%'
        },
        {
            field: 'branch',
            title: 'Branch',
            width: '10%'
        },
        {
            field: 'specialEquipmentBranchID',
            title: 'SpecialEquipmentBranchID',
            width: '10%',
            hidden: true
        },
        {
            field: 'specialEquipmentCode',
            title: 'Code',
            width: '10%',
            hidden: true
        },
        {
            field: 'specialEquipmentCreatedBy',
            title: 'Created By',
            width: '10%'
        },
        {
            field: 'specialEquipmentQuantity',
            title: 'Total Quantity',
            width: '10%'
        },
        {
            field: 'specialEquipmentQuantityAvailable',
            title: 'Quantity Available',
            width: '10%'
        },
        {
            field: 'specialEquipmentIsActive',
            title: 'Active?',
            width: '5%'
        }
    ];

    const editButton = (field) => (value, record) => {
        const spn = document.createElement('span');
        const lnk = (
            <Grid container justifyContent="center" style={{ textAlign: 'center', display: 'block' }}>
                <SquareButton
                    //toolTipTitle="Update Special Equipment"
                    style={{
                        backgroundColor: '#183B68',
                        borderRadius: '20%',
                        width: '32px',
                        height: '32px',
                        textAlign: 'center',
                        padding: '0px',
                        marginBottom: '5px'
                    }}
                    onClick={() => {
                        handleModal('showAddEdit', true);
                        setState((st) => ({
                            ...st,
                            focusedRow: record
                        }));
                    }}
                >
                    <Edit fontSize="small" style={{ color: 'white' }} />
                </SquareButton>

                <SquareButton
                    //toolTipTitle="View Special Equipment History"
                    style={{
                        backgroundColor: '#183B68',
                        borderRadius: '20%',
                        width: '32px',
                        height: '32px',
                        textAlign: 'center',
                        padding: '0px',
                        marginBottom: '5px'
                    }}
                    onClick={() => {
                        handleModal('showHistory', true);
                        setState((st) => ({
                            ...st,
                            focusedRow: record
                        }));
                    }}
                >
                    <History fontSize="small" style={{ color: 'white' }} />
                </SquareButton>

                <SquareButton
                    style={{
                        backgroundColor: '#183B68',
                        borderRadius: '20%',
                        width: '32px',
                        height: '32px',
                        textAlign: 'center',
                        padding: '0px',
                        marginBottom: '5px'
                    }}
                    onClick={() => {
                        handleModal('showUsage', true);
                        setState((st) => ({
                            ...st,
                            focusedRow: record
                        }));
                    }}
                //toolTipTitle="View Active Special Equipment"
                >
                    <Assignment fontSize="small" style={{ color: 'white' }} />
                </SquareButton>
            </Grid>
        );
        ReactDOM.render(lnk, spn);
        return spn;
    };

    const barcodeField = (field) => (value, record) => {
        const spn = document.createElement('span');
        const lnk = (
            <Grid container justifyContent="space-evenly">
                <Barcode value={record.specialEquipmentCode} />
            </Grid>
        );
        ReactDOM.render(lnk, spn);
        return spn;
    };

    const columns = useMemo(() => {
        const tempCols = [...baseColumns];
        let actionCol = tempCols.find((element) => element.field === 'action');
        let activeCol = tempCols.find((element) => element.field === 'specialEquipmentIsActive');
        let barcodeCol = tempCols.find((element) => element.field === 'barcode');

        if (actionCol) {
            actionCol.renderer = editButton({});
        }

        if (activeCol) {
            activeCol.renderer = (val) => (val ? 'Yes' : 'No');
        }

        if (barcodeCol) {
            barcodeCol.renderer = barcodeField({});
        }

        return tempCols;
    }, []);

    const handleModal = (modal, val) => {
        if (val) {
            setState((st) => ({
                ...st,
                [modal]: val,
                isReload: false
            }));
        } else {
            setState((st) => ({
                ...st,
                [modal]: val,
                focusedRow: null,
                isReload: true
            }));
        }
    };

    const history = useHistory();
    const params = history.location.state

    let BaseUrl = () => {
        const branch = params?.branch;
        return `SpecialEquipment/SpecialEquipment/${branch ? branch : ""}`
    }

    useEffect(() => {
        if (params?.page == "usage") {
            setState((st) => ({
                ...st,
                showUsage: true,
                focusedRow: { specialEquipmentID: params?.equipmentID, specialEquipmentCode: params?.equipmentCode }
            }));
        }
        else if (params?.page == "history") {
            setState((st) => ({
                ...st,
                showHistory: true,
                focusedRow: { specialEquipmentID: params?.equipmentID, specialEquipmentCode: params?.equipmentCode }
            }));
        }
    }, [history]);


    return (
        <div>
            {state.showHistory ? (
                <SpecialEquipmentHistoryModal activeOnly={false} focusedRow={state.focusedRow} onClose={() => handleModal('showHistory', false)} />
            ) : state.showUsage ? (
                <SpecialEquipmentHistoryModal activeOnly={true} focusedRow={state.focusedRow} onClose={() => handleModal('showUsage', false)} />
            ) : (
                <>
                    <Grid item container justify="space-between">
                        <Grid item>
                            <BreadCrumbs crumbs={crumbs()} />
                        </Grid>
                        <Grid item style={{ marginTop: '15px' }}>
                            <SecondaryButton variant="contained" color="secondary" onClick={() => handleModal('showAddEdit', true)} className="btnadd">
                                Add Special Equipment
                            </SecondaryButton>
                        </Grid>
                    </Grid>

                    <Grid item container spacing={1} xs={12} style={{}}>
                        <Grid item xs={12}>
                            <ReportGijgoGrid
                                dataConverter={(res) => ({
                                    records: res?.data,
                                    total: res?.data?.total
                                })}
                                columns={columns}
                                getUrl={BaseUrl()}
                                isReload={state.isReload}
                            />
                        </Grid>
                    </Grid>

                    {state.showAddEdit ? <AddEditSpecialEquipment focusedRow={state.focusedRow} onClose={() => handleModal('showAddEdit', false)} /> : null}
                </>
            )}
        </div>
    );
};
export default SpecialEquipment;
