import { unAuthMenu, adminMenu, customerMenu, internalUserMenu, auditorMenu, technicianMenu, webFormRoutes } from './menu';
import TreeImgGallery from '../../Components/_shared/treeImgGallery';
import ResetPasswordBYInternal from '../../Components/internal/resetPassword';
import OpenActionLog from '../../Components/internal/Communicator/OpenActionLogs';
import SiteVehicles from '../../Components/internal/Communicator/SiteVehicles';
import BookingsScreen from '../../Components/internal/Communicator/Bookings';
import * as Components from '../../Components';
import AssessmentIcon from '@material-ui/icons/Assessment';
import ForumIcon from '@material-ui/icons/Forum';
import RotateLeftIcon from '@material-ui/icons/RotateLeft';
import LockIcon from '@material-ui/icons/Lock';

import { AppStorage } from '../Service/storage-service';

import { TypeOfAuth } from './menu/menu-auth-type';

const EwipMenus = ['Dashboard Detail', 'Archived Jobs', 'Videos', 'Dashboard', 'QC Jobs'];

export const getMenuForRole = (roles, isLoggedIn) => {
    const portalSettings = AppStorage.getPortalSetting();

    let menus = [];
    if (!isLoggedIn) {
        menus.push(...unAuthMenu);
    } else {
        const isCommunicatorAllowed = AppStorage.getCommunicatorAllowed() && portalSettings.portalSettingCommunicatorEnabled;
        const isInvoiceAllowed = AppStorage.getInvoiceAllowed() && portalSettings.portalSettingInvoiceEnabled;
        const isReportingAllowed = AppStorage.getReportingAllowed() && portalSettings.portalSettingReportingEnabled;
        const isWIPAllowed = AppStorage.getWIPAllowed() && portalSettings.portalSettingeWIPEnabled;
        const isSpecialEquipmentAllowed = AppStorage.getSpecialEquipmentAllowed() && portalSettings.portalSettingSpecialEquipmentEnabled;
        let menuPermissionCounter = AppStorage.menuPermissionCounter();
        const isReportingBudgetDashboardAllowed = AppStorage.getReportingBudgetDashboardAllowed();
        const isReportingAgedMeasureAllowed = AppStorage.getReportingAgedMeasureAllowed();
        const isReportingDOCAllowed = AppStorage.getReportingDOCAllowed();
        const isReportingPartsAllowed = AppStorage.getReportingPartsAllowed();
        const isReportingSalesAllowed = AppStorage.getReportingSalesAllowed();
        const isReportingServiceAllowed = AppStorage.getReportingServiceAllowed();
        const isReportingKPIsAllowed = AppStorage.getReportingKPIsAllowed();
        const isReportingWorkShopAllowed = AppStorage.getReportingWorkShopAllowed();
        let reportingMenuScreenConfig = [
            { label: 'Budget Dashboard', isAllowed: isReportingBudgetDashboardAllowed },
            { label: 'Profit & Loss', isAllowed: isReportingBudgetDashboardAllowed },
            { label: 'Aged Measures', isAllowed: isReportingAgedMeasureAllowed },
            { label: 'DOC', isAllowed: isReportingDOCAllowed },
            { label: 'Sales', isAllowed: isReportingSalesAllowed },
            { label: 'Services', isAllowed: isReportingServiceAllowed },
            { label: 'Parts', isAllowed: isReportingPartsAllowed }
        ];
        roles.forEach((role) => {
            switch (role) {
                case 'admin':
                    //For admin we used PortalSetting Validations
                    menus.push(...adminMenu);
                    if (!portalSettings.portalSettingCommunicatorEnabled) {
                        let Communicator = menus.findIndex((m) => m.label == 'Communicator');
                        menus.splice(Communicator, 1);
                    }
                    if (!portalSettings.portalSettingeWIPEnabled) {
                        let AdmineWIPMenus = menus.findIndex((m) => m.label == 'eWIP');
                        menus.splice(AdmineWIPMenus, 1);
                    }
                    if (!portalSettings.portalSettingInvoiceEnabled) {
                        let AdminInvoice = menus.findIndex((m) => m.label == 'Invoices');
                        menus.splice(AdminInvoice, 1);
                    }

                    break;
                case 'customer':
                    menus.push(...customerMenu);
                    if (!isCommunicatorAllowed) {
                        let Communicator = menus.findIndex((m) => m.label == 'Communicator');
                        menus.splice(Communicator, 1);
                    }
                    if (!isWIPAllowed) {
                        let eWIP = menus.findIndex((m) => m.label == 'eWIP');
                        menus.splice(eWIP, 1);
                    }
                    if (!isInvoiceAllowed) {
                        let InternalInvoice = menus.findIndex((m) => m.label == 'Invoices');
                        menus.splice(InternalInvoice, 1);
                    }

                    if (menuPermissionCounter == 1) {
                        menus = [...menus].flatMap((elem) => {
                            if (elem.children && elem.children.length > 0 && !elem.dontFlatListChildren) {
                                if (isWIPAllowed) {
                                    let DahboardMenuIndex = elem.children.findIndex((p) => p.path === '/cust_dashboard');
                                    if (DahboardMenuIndex >= 0) {
                                        elem.children.splice(DahboardMenuIndex, 1);
                                    }
                                }
                                if (isInvoiceAllowed) {
                                    let DahboardMenuIndex = elem.children.findIndex((p) => p.path === '/invoice/dashboard');
                                    if (DahboardMenuIndex >= 0) {
                                        elem.children.splice(DahboardMenuIndex, 1);
                                    }
                                } else if (isCommunicatorAllowed) {
                                    let DahboardMenuIndex = elem.children.findIndex((p) => p.path === '/communicator_dash');
                                    if (DahboardMenuIndex >= 0) {
                                        elem.children.splice(DahboardMenuIndex, 1);
                                    }
                                }
                                return elem.children;
                            } else {
                                return elem;
                            }
                        });
                    }

                    break;
                case 'internal':
                    menus.push(...internalUserMenu);
                    if (!isWIPAllowed) {
                        let eWIP = menus.findIndex((m) => m.label == 'eWIP');
                        menus.splice(eWIP, 1);
                    }

                    if (isWIPAllowed && !isSpecialEquipmentAllowed) {
                        let eWIPIndex = menus.findIndex((m) => m.label == 'eWIP');
                        menus[eWIPIndex].children = menus[eWIPIndex].children.filter((m) => m.label != 'Special Equipments');
                    }

                    if (!isCommunicatorAllowed) {
                        let Communicator = menus.findIndex((m) => m.label == 'Communicator');
                        menus.splice(Communicator, 1);
                    }

                    if (!isReportingAllowed) {
                        let ReportsIndex = menus.findIndex((m) => m.label == 'Reports');
                        menus.splice(ReportsIndex, 1);
                    } else {
                        let ReportsIndex = menus.findIndex((m) => m.label == 'Reports');
                        reportingMenuScreenConfig.forEach((k) => {
                            if (!k.isAllowed) {
                                let reportingMenuScreenIndex = menus[ReportsIndex].children.findIndex((m) => m.label == k.label);
                                if (reportingMenuScreenIndex >= 0) {
                                    menus[ReportsIndex].children.splice(reportingMenuScreenIndex, 1);
                                }
                            }
                        });
                    }
                    if (!isInvoiceAllowed) {
                        let InternalInvoice = menus.findIndex((m) => m.label == 'Invoices');
                        menus.splice(InternalInvoice, 1);
                    }

                    if (menuPermissionCounter == 1) {
                        menus = [...menus].flatMap((elem) => {
                            if (elem.children && elem.children.length > 0 && !elem.dontFlatListChildren) {
                                if (isWIPAllowed) {
                                    let DahboardMenuIndex = elem.children.findIndex((p) => p.path === '/eWIPDashboard');
                                    if (DahboardMenuIndex >= 0) {
                                        elem.children[DahboardMenuIndex].path = '/';
                                    }
                                } else if (isReportingAllowed) {
                                    let DahboardMenuIndex = elem.children.findIndex((p) => p.path === '/ReportsDashboard');
                                    if (DahboardMenuIndex >= 0) {
                                        elem.children[DahboardMenuIndex].path = '/';
                                    }
                                } else if (isCommunicatorAllowed) {
                                    let DahboardMenuIndex = elem.children.findIndex((p) => p.path === '/bookings');
                                    if (DahboardMenuIndex >= 0) {
                                        elem.children[DahboardMenuIndex].path = '/';
                                    }
                                } else if (isInvoiceAllowed) {
                                    let DahboardMenuIndex = elem.children.findIndex((p) => p.path === '/invoice/dashboard');
                                    if (DahboardMenuIndex >= 0) {
                                        elem.children[DahboardMenuIndex].path = '/';
                                    }
                                }
                                return elem.children;
                            } else {
                                return elem;
                            }
                        });
                    } else {
                        if (isWIPAllowed) {
                            let MenuIndex = menus.findIndex((p) => p.label === 'eWIP');
                            let DahboardMenuIndex = menus[MenuIndex].children.findIndex((p) => p.path === '/eWIPDashboard');
                            if (DahboardMenuIndex >= 0) {
                                menus[MenuIndex].children[DahboardMenuIndex].path = '/';
                            }
                        } else if (isReportingAllowed) {
                            let MenuIndex = menus.findIndex((p) => p.label === 'Reports');
                            let DahboardMenuIndex = menus[MenuIndex].children.findIndex((p) => p.path === '/ReportsDashboard');
                            if (DahboardMenuIndex >= 0) {
                                menus[MenuIndex].children[DahboardMenuIndex].path = '/';
                            }
                        } else if (isCommunicatorAllowed) {
                            let MenuIndex = menus.findIndex((p) => p.label === 'Communicator');
                            let DahboardMenuIndex = menus[MenuIndex].children.findIndex((p) => p.path === '/bookings');
                            if (DahboardMenuIndex >= 0) {
                                menus[MenuIndex].children[DahboardMenuIndex].path = '/';
                            }
                        } else if (isInvoiceAllowed) {
                            let MenuIndex = menus.findIndex((p) => p.label === 'Invoices');
                            let DahboardMenuIndex = menus[MenuIndex].children.findIndex((p) => p.path === '/invoice/dashboard');
                            if (DahboardMenuIndex >= 0) {
                                menus[MenuIndex].children[DahboardMenuIndex].path = '/';
                            }
                        }
                    }

                    if (AppStorage.getCanResetPassword()) {
                        menus.push({
                            label: 'Reset Password',
                            icon: <LockIcon />,
                            exact: true,
                            path: '/resetpassword',
                            component: (props) => <ResetPasswordBYInternal {...props} />,
                            authType: TypeOfAuth.Auth
                        });
                    }
                    break;
                case 'auditor':
                    menus.push(...auditorMenu);
                    break;
                case 'technician':
                    menus.push(...technicianMenu);
                    break;
            }
        });
        menus.push(...webFormRoutes);

        menus.push({
            path: '/treeImgs/:count',
            hidden: true,
            component: (props) => <TreeImgGallery {...props} />,
            authType: TypeOfAuth.Auth
        });
    }

    return menus.filter((m) => m.authType === TypeOfAuth.Both || m.authType === (isLoggedIn ? TypeOfAuth.Auth : TypeOfAuth.NonAuth));
};
