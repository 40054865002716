import {get, post } from './http-calls';



export const addUpdateSpecialEquipment = async (data) => {
    let res = await post('SpecialEquipment/SpecialEquipment', data, { useAuthToken: true });
    return res;
};

export const addUpdateSpecialEquipmentUsed = async (data) => {
    let res = await post('SpecialEquipment/SpecialEquipmentUsed', data, { useAuthToken: true });
    return res;
};

export const getSpecialEquipment = async(branchID) => {
    let res = await get(`SpecialEquipment/SpecialEquipment/${branchID}`, { useAuthToken: true });
    return res;
};

export const getSpecialEquipmentDash = async() => {
    let res = await get(`SpecialEquipment/SpecialEquipment/Dash`, { useAuthToken: true });
    return res;
};

export const getSpecialEquipmentAssignlist = async(headerID) => {
    let res = await get(`SpecialEquipment/SpecialEquipment/AssignList/${headerID}`, { useAuthToken: true });
    return res;
};