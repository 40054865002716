import React, { useEffect, useContext, useState } from 'react';
import Grid from '@material-ui/core/Grid';
import AppContext from '../../App/AppContext';
import { Link, useHistory } from 'react-router-dom';
import { Avatar, Card, CardContent, Typography, Container, makeStyles, Tooltip } from '@material-ui/core';
import '../Auditor/Dashboard/dashboard.scss';
import { getDashboardForInvoice } from '../../Core/Service/InvoiceScreen-service';
import EmailIcon from '@material-ui/icons/Email';
import DescriptionIcon from '@material-ui/icons/Description';
import PeopleIcon from '@material-ui/icons/People';
import { getCustomerDashboard } from '../../Core/Service/customer-services';
import { getCommunicatorDash } from '../../Core/Service/CustUserCommunicator/communicator-service';
import { AppStorage } from './../../Core/Service/storage-service';
import LocalShippingIcon from '@material-ui/icons/LocalShipping';
import DriveEtaIcon from '@material-ui/icons/DriveEta';
import TimerIcon from '@material-ui/icons/Timer';
import CalendarTodayIcon from '@material-ui/icons/CalendarToday';
import DateRangeIcon from '@material-ui/icons/DateRange';
import MessageIcon from '@material-ui/icons/Message';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import LiveHelpIcon from '@material-ui/icons/LiveHelp';
import AnnouncementIcon from '@material-ui/icons/Announcement';
import ForumIcon from '@material-ui/icons/Forum';
import TelegramIcon from '@material-ui/icons/Telegram';
import CheckIcon from '@material-ui/icons/Check';
import DoneAllIcon from '@material-ui/icons/DoneAll';
import LibraryAddCheckIcon from '@material-ui/icons/LibraryAddCheck';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import DepartureBoardIcon from '@material-ui/icons/DepartureBoard';
import EventAvailableIcon from '@material-ui/icons/EventAvailable';
import DialogComp from '../../Core/Modal/dialogModal';
import BookingRequestedModal from './bookingRequested/bookingRequestedModal';
import { SecondaryButton } from '../../Core/FormInput';
import CancelPresentationIcon from '@material-ui/icons/CancelPresentation';
import SendIcon from '@material-ui/icons/Send';
import ScheduleIcon from '@material-ui/icons/Schedule';
const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        border: '2px solid rgb(90 114 123 / 20%)',
        Opacity: 0.9,
        minWidth: 345,
        borderRadius: 15,
        boxShadow: 'rgb(90 114 123 / 11%) 0px 7px 30px 0px',
        paddingBottom: 20
    },

    avatar: {
        height: 40,
        width: 40
    }
}));

export const RenderTiles = (props) => {
    const classes = useStyles();
    const { cards, label } = props;
    let history = useHistory();

    let onChange = (val, logID) => {
        let tempUrlObj = { state: logID ? logID : '' };
        if (val.pathname) {
            tempUrlObj = { ...val, ...tempUrlObj };
        } else {
            tempUrlObj = { pathname: val, ...tempUrlObj };
        }
        history.push(tempUrlObj);
    };

    return (
        <div>
            <Card className={classes.root}>
                <Typography variant="h5" style={{ background: 'white', color: 'black', padding: 20, textAlign: 'center' }}>
                    {props.label}
                </Typography>

                <Grid container style={{ background: 'white' }} justify="flex-start" spacing={2}>
                    {cards?.map((m, i) => {
                        let Icons = m.icon;
                        return (
                            <Tooltip title={m.name} arrow key={`card${i}`}>
                                <Grid item xs={12} sm={6} md={4} xl={3}>
                                    <div
                                        style={{
                                            width: '100%',
                                            display: 'flex',
                                            padding: '10px'
                                            // border: '1px solid rgb(90 114 123 / 4%)'
                                        }}
                                    >
                                        <div style={{ width: '6%' }}></div>
                                        <div style={{ width: '16%', marginTop: 5 }}>
                                            <Avatar
                                                aria-label="recipe"
                                                className={classes.avatar}
                                                style={{ backgroundColor: m.color, boxShadow: ` 0px 0px 0px 8px ${m?.color}` }}
                                            >
                                                {Icons}
                                            </Avatar>
                                        </div>

                                        <div
                                            style={{
                                                // marginLeft: 10,
                                                width: '80%',
                                                cursor: m?.url ? 'pointer' : 'auto',
                                                textOverflow: 'ellipsis',
                                                whiteSpace: 'nowrap',
                                                overflow: 'hidden',
                                                textAlign: 'left'
                                                // paddingBottom: 17
                                            }}
                                            onClick={() => onChange(m?.url, m.logID)}
                                        >
                                            <div style={{ fontSize: 17, marginBottom: 0, fontWeight: 530 }}> {m?.name}</div>

                                            <span style={{ fontSize: 26, fontWeight: 600 }}> {m?.count}</span>
                                        </div>
                                    </div>
                                </Grid>
                            </Tooltip>
                        );
                    })}
                </Grid>
            </Card>
        </div>
    );
};
const CustCommonDash = (props) => {
    const portalSettings = AppStorage.getPortalSetting();
    let menuPermissionCounter = AppStorage.menuPermissionCounter();
    const [state, setState] = useState({
        InvoiceData: [],
        eWIPData: [],
        communicatorData: [],
        isReload: null
    });
    const { showToast, userRoles } = useContext(AppContext);
    const isCustomer = userRoles.includes('customer');
    const isInternal = userRoles.includes('internal');

    useEffect(async () => {
        if (AppStorage.getWIPAllowed() && portalSettings.portalSettingeWIPEnabled) {
            let res2 = await getCustomerDashboard();
            if (res2.success) {
                setState((st) => ({ ...st, eWIPData: res2.data }));
            }
            if (!res2.success) {
                showToast(res2.message);
            }
        }

        if (AppStorage.getInvoiceAllowed()) {
            let res = await getDashboardForInvoice();
            if (res.success) {
                setState((st) => ({ ...st, InvoiceData: res.data.list[0] }));
            } else {
                showToast(res.message);
            }
        }

        if (AppStorage.getCommunicatorAllowed()) {
            let communicator = await getCommunicatorDash();
            if (communicator.success) {
                setState((st) => ({ ...st, communicatorData: communicator.data?.list[0] }));
            }
            if (!communicator.success) {
                showToast(communicator.message);
            }
        }
    }, [state.isReload]);

    const bookingReqShowModal = () => {
        setState((st) => ({ ...st, isBookingRequesteModal: true }));
    };

    const closeModal = (val) => {
        setState((st) => {
            let newSt = { ...st };
            newSt.isBookingRequesteModal = false;
            if (val) {
                newSt.isReload = new Date();
            }
            return newSt;
        });
    };

    const InvoiceMember = [
        {
            id: 1,
            name: 'All Invoices',
            color: '#1abc9c',
            count: state.InvoiceData.allInvoices || 0,
            icon: <ForumIcon />,
            url: '/invoice/allInvoices'
        },
        {
            id: 2,
            name: 'Invoices Outstanding',
            color: '#e74c3c',
            count: state.InvoiceData.unPaidInvoices || 0,
            icon: <AnnouncementIcon />,
            url: '/invoice/Invoice_Outstanding'
        },
        {
            id: 3,
            name: 'Invoices Queried',
            color: '#ff9f00',
            count: state.InvoiceData.queriedCount || 0,
            icon: <LiveHelpIcon />,
            url: '/invoice/Invoice_Queried'
        },
        {
            id: 4,
            name: 'Invoices Paid',
            color: '#1abc9c',
            count: state.InvoiceData.paidInvoices || 0,
            icon: <DoneAllIcon />,
            url: '/invoice/Invoice_Paid'
        },
        {
            id: 5,
            name: 'Messages Sent',
            color: '#95a5a6',
            count: state.InvoiceData.messagesSent || 0,
            icon: <TelegramIcon />,
            url: '/invoice/messagesSent'
        },
        {
            id: 5,
            name: 'Messages Received',
            color: '#9b6bcc',
            count: state.InvoiceData.messagesReceived || 0,
            icon: <EmailIcon />,
            url: '/invoice/messagesReceived'
        }
        // {
        //     id: 5,
        //     name: 'Total Customer',
        //     color: '#2ecc71',
        //     count: state.InvoiceData.customerCount || 0,
        //     icon: <PeopleIcon />,
        //     url: '/invoice/Customers'
        // }
    ];
    const eWIPDataMember = [
        {
            id: 1,
            name: 'Current Month',
            color: '#3498db',
            icon: <DateRangeIcon />,
            count: state.eWIPData.currentMonth,
            url: '/Customer/Current Month/1'
        },
        {
            id: 2,
            name: 'Current Year',
            color: '#2ecc71',
            icon: <CalendarTodayIcon />,
            count: state.eWIPData.currentYear,
            url: '/Customer/Current Year/2'
        },
        {
            id: 3,
            name: 'Last Year',
            color: '#dc3545',
            icon: <CalendarTodayIcon />,
            count: state.eWIPData.preYear,
            url: '/Customer/Last Year/3'
        },
        {
            id: 4,
            name: 'Total',
            color: '#868e96',
            icon: <DescriptionIcon />,
            count: state.eWIPData.total,
            url: '/Customer/Total/4'
        }
    ];

    const CommunicatorMember = [
        {
            id: 1,
            name: 'Awaiting Authority',
            color: '#3498db',
            icon: <TimerIcon />,
            count: state.communicatorData.awaitingAuthorityCount,
            url: isInternal ? { pathname: '/SiteVehicles', search: `?status=AUTH` } : '/AwaitingAuthority'
        },
        // {
        //     id: 2,
        //     name: 'Site Vehicles',
        //     color: '#2ecc71',
        //     icon: <DriveEtaIcon />,
        //     count: state.communicatorData.siteVehiclesCount,
        //     url: '/SiteVehicles'
        // },
        // {
        //     id: 10,
        //     name: 'Bookings',
        //     color: '#3498db',
        //     icon: <EventAvailableIcon />,
        //     count: state.communicatorData.bookingCount,
        //     url: { pathname: '/SiteVehicles', search: `?status=BOOKED` }
        // },
        {
            id: 17,
            name: 'Work Completed',
            color: '#2ecc71',
            icon: <LibraryAddCheckIcon />,
            count: state.communicatorData.workCompletedCount,
            url: { pathname: '/SiteVehicles', search: `?status=WC` },
            isShowCustomer: true
        },
        {
            id: 18,
            name: ' Awaiting Collection',
            color: '#2ecc71',
            icon: <DepartureBoardIcon />,
            count: state.communicatorData.awaitingCollectionCount,
            url: { pathname: '/SiteVehicles', search: `?status=ACOLLECTION` },
            isShowCustomer: true
        },
        {
            id: 3,
            name: 'VOR Vehicles',
            color: '#dc3545',
            icon: <LocalShippingIcon />,
            count: state.communicatorData.vorVehiclesCount,
            url: '/VORVehicles'
        }
    ];

    let permissionCounterCustList = [
        {
            id: 1,
            name: 'VOR Vehicles',
            color: '#dc3545',
            icon: <LocalShippingIcon />,
            count: state.communicatorData.vorVehiclesCount,
            url: { pathname: '/SiteVehicles', search: `?status=VOR` }
        },
        // {
        //     id: 4,
        //     name: 'Awaiting Full Order',
        //     color: '#dc3545',
        //     icon: <ShoppingCartIcon />,
        //     count: state.communicatorData.awaitingFullOrderNumberCount,
        //     url: { pathname: '/SiteVehicles', search: `?status=AFON` }
        // },
        // {
        //     id: 13,
        //     name: 'Site Vehicles',
        //     color: '#3498db',
        //     icon: <DriveEtaIcon />,
        //     count: state.communicatorData.siteVehiclesCount,
        //     url: { pathname: '/SiteVehicles', search: `?status=S` }
        // },
        {
            id: 17,
            name: 'Work Completed',
            color: '#2ecc71',
            icon: <LibraryAddCheckIcon />,
            count: state.communicatorData.workCompletedCount,
            url: { pathname: '/SiteVehicles', search: `?status=WC` }
        },
        {
            id: 18,
            name: ' Awaiting Collection',
            color: '#2ecc71',
            icon: <DepartureBoardIcon />,
            count: state.communicatorData.awaitingCollectionCount,
            url: { pathname: '/SiteVehicles', search: `?status=ACOLLECTION` }
        }
    ];

    const CommunicatorBookingsSec = [
        {
            id: 10,
            name: 'Bookings',
            color: '#3498db',
            icon: <EventAvailableIcon />,
            count: state.communicatorData.bookingCount,
            url: { pathname: '/SiteVehicles', search: `?status=BOOKED` },
            isShowCustomer: true
        },
        {
            id: 10,
            name: isInternal ? 'Request Received' : 'Request Sent',
            color: '#3498db',
            icon: <EmailIcon />,
            count: state.communicatorData.bookingRequestCount,
            url: { pathname: '/BookingsRequesteList', search: `?status=1` },
            isShowCustomer: true
        },

        ...(!isInternal
            ? [
                  {
                      id: 10,
                      name: 'Re-Schedule Received',
                      color: '#3498db',
                      icon: <ScheduleIcon />,
                      count: state.communicatorData.bookingReScheduleReceivedSentCount,
                      url: { pathname: '/BookingsRequesteList', search: `?status=2` }
                  },
                  {
                      id: 10,
                      name: 'Re-Schedule Sent',
                      color: '#3498db',
                      icon: <SendIcon />,
                      count: state.communicatorData.bookingReScheduleSentCount,
                      url: { pathname: '/BookingsRequesteList', search: `?status=3` },
                      isShowCustomer: true
                  }
              ]
            : [
                  {
                      id: 10,
                      name: 'Re-Schedule Sent',
                      color: '#3498db',
                      icon: <SendIcon />,
                      count: state.communicatorData.bookingReScheduleSentCount,
                      url: { pathname: '/BookingsRequesteList', search: `?status=2` }
                  },

                  {
                      id: 10,
                      name: 'Re-Schedule Received',
                      color: '#3498db',
                      icon: <ScheduleIcon />,
                      count: state.communicatorData.bookingReScheduleReceivedSentCount,
                      url: { pathname: '/BookingsRequesteList', search: `?status=3` },
                      isShowCustomer: true
                  }
              ]),

        {
            id: 10,
            name: 'Booking Confirmed',
            color: '#2ecc71',
            icon: <EventAvailableIcon />,
            count: state.communicatorData.bookingConfirmedCount,
            url: { pathname: '/BookingsRequesteList', search: `?status=4` },
            isShowCustomer: true
        },
        {
            id: 10,
            name: 'Booking Cancelled',
            color: '#dc3545',
            icon: <CancelPresentationIcon />,
            count: state.communicatorData.bookingCancelCount,
            url: { pathname: '/BookingsRequesteList', search: `?status=5` },
            isShowCustomer: true
        }
    ];

    const CommunicatorHeaderComp = () => {
        return (
            <div style={{ position: 'relative' }}>
                Bookings
                <div style={{ position: 'absolute', left: '0px', bottom: '-3px' }}>
                    <SecondaryButton onClick={bookingReqShowModal}>Request Booking</SecondaryButton>
                </div>
            </div>
        );
    };

    return (
        <div style={{ margin: 10 }}>
            <Grid
                container
                spacing={1}
                direction="row"
                // justify="space-between"
                alignItems="center"
                item
                xs={12}
                sm={12}
                md={12}
                lg={12}
            >
                {AppStorage.getWIPAllowed() && portalSettings.portalSettingeWIPEnabled ? (
                    <Grid item xs={12} style={{ padding: '6px 8px' }}>
                        <RenderTiles cards={eWIPDataMember} label="eWIP" />
                    </Grid>
                ) : null}
                {AppStorage.getInvoiceAllowed() && (
                    <Grid item xs={12} style={{ padding: '6px 8px' }}>
                        <RenderTiles cards={InvoiceMember} label="Invoices" />
                    </Grid>
                )}
                {AppStorage.getCommunicatorAllowed() && (
                    <Grid item xs={12} style={{ padding: '6px 8px' }}>
                        <RenderTiles cards={menuPermissionCounter == 1 ? permissionCounterCustList : CommunicatorMember} label="Communicator" />
                        <RenderTiles cards={CommunicatorBookingsSec} label={CommunicatorHeaderComp()} />
                    </Grid>
                )}
            </Grid>
            {state.isBookingRequesteModal && (
                // <DialogComp title="Bookings" onClose={() => closeModal(false)} maxWidth="md" fullWidth overflow="auto">
                <BookingRequestedModal onClose={closeModal} statusList={props.vehicleStatusList} headerRecordID={props.headerRecordID} />
                // </DialogComp>
            )}
        </div>
    );
};
export default CustCommonDash;
