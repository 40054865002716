import { FormLabel, Grid } from '@material-ui/core';
import React from 'react';
import { useContext } from 'react';
import { useState } from 'react';
import AppContext from '../../../App/AppContext';
import { GreenButton, SecondaryButton, TextBox } from '../../../Core/FormInput';
import { postCustomerRating } from '../../../Core/Service/workflow-service';
// import { SelectBox } from '';
import SelectBox from './../../../Core/FormInput/SelectBox';
import StarIcon from '@material-ui/icons/Star';

const CustomerRating = (props) => {
    const [state, setState] = useState({ customerRatingIcon: null });
    const { hideTopNavAndMenu, showToast, portalSettings } = useContext(AppContext);

    const fieldChange = (e) => {
        const { name, value } = e.target;
        setState((st) => {
            const nst = { ...st, [name]: value };
            // nst.isSetDisabled = nst.jobPriority ? false : true;
            return nst;
        });
    };

    const ratingList = [
        {
            id: 1,
            name: (
                <>
                    <StarIcon style={{ color: 'gold' }} /> Gold
                </>
            )
        },
        {
            id: 2,
            name: (
                <>
                    <StarIcon style={{ color: '#C0C0C0' }} /> Silver
                </>
            )
        },
        {
            id: 3,
            name: (
                <>
                    <StarIcon style={{ color: '#CD7F32' }} /> Bronze
                </>
            )
        }
    ];

    const handelSubmit = async () => {
        let data = {
            headerRecordID: props.jobId,
            customerRatingIcon: state.customerRatingIcon,
            customerRatingNotes: state.customerRatingNotes,
            enteredRegNo: state.enteredRegNo
        };
        const res = await postCustomerRating(data);
        if (res.success) {
            showToast(res.data.list.table[0].msg);
            props.handleCancel(true);
        }
        if (!res.success) {
            console.error(res);
        }
    };

    return (
        <Grid container spacing={2} alignItems="flex-end">
            <Grid item xs={12}>
                WIP No:&nbsp; <b> {props.workFlowData.wipNo}</b> &nbsp;&nbsp; Reg. No:&nbsp; <b> {props.workFlowData.registration}</b>
            </Grid>
            <br />
            {portalSettings.showRegNumberInCustomerRatingBox && (
                <Grid item xs={12}>
                    <FormLabel component="legend">Reg Number</FormLabel>
                    <TextBox name="enteredRegNo" value={state.enteredRegNo} onChange={fieldChange} />
                </Grid>
            )}
            <Grid item xs={12}>
                <FormLabel component="legend" style={{ marginBottom: 10 }}>
                    Customer Rating Icon
                </FormLabel>
                <SelectBox List={ratingList} name="customerRatingIcon" value={state.CustomerRatingIcon} onChange={fieldChange} />
            </Grid>
            <Grid item xs={12}>
                <FormLabel component="legend">Notes</FormLabel>
                <TextBox rows={4} multiline name="customerRatingNotes" value={state.customerRatingNotes} onChange={fieldChange} />
            </Grid>

            <Grid item xs={12}>
                <SecondaryButton className="Submitbtn" onClick={() => handelSubmit('reset')}>
                    Submit
                </SecondaryButton>
            </Grid>
        </Grid>
    );
};

export default CustomerRating;
