import React, { useMemo } from 'react';
import { moment } from 'moment';
import { Grid, FormLabel } from '@material-ui/core';
import BreadCrumbs from '../../../../../Core/Controls/Breadcrumb';
import ReportGijgoGrid from '../../GijgoForReportsScreen';
import { useHistory } from 'react-router-dom';
import ReactDOM from 'react-dom';

const baseColumns = [
    { field: 'salesExec', sortable: false, title: `Sales Exec`, width: 180, filterable: false },
    { field: 'currentMonthTotal', sortable: true, title: `Current`, width: 120, showColumnTotal: true, align: 'right', filterable: false },
    { field: 'oneMonthTotal', sortable: true, title: `1 Month`, width: 150, showColumnTotal: true, align: 'right', filterable: false },
    { field: 'twoMonthsTotal', sortable: true, title: `2 Month`, width: 120, showColumnTotal: true, align: 'right', filterable: false },
    { field: 'threeMonthsTotal', sortable: true, title: `3 Month`, width: 120, showColumnTotal: true, align: 'right', filterable: false },
    { field: 'fourMonthsAndOverTotal', sortable: true, title: '4+ Month', width: 120, showColumnTotal: true, align: 'right', filterable: false },
    { field: 'unallocatedItemsTotal', title: 'Unallocated', width: 120, showColumnTotal: true, align: 'right', filterable: false, sortable: false }
];
const crumbs = (measuredesc) => [
    { name: 'Home', path: '/' },
    { name: 'Sales', active: true },
    { name: 'Account Queries Sales exec', active: true }
];
let addCommas = (val) => {
    return val ? `£${val.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}` : '£0.00';
};
const AccountQueriesbySalesexec = (props) => {
    const history = useHistory();
    const redirectToScreens = (value, record, MeasureID, field) => {
        let searchQuery = `?&measuredesc=${'Account Queries by Salesexec'}&month=${field.month == 0 ? 0 : field.month || ''}&MeasureID=${
            field.MeasureID || ''
        }&unallocated=${field.unallocated || ''}&isGroupingById=accountCode&salesexec=${record.salesExec||''}&PRSentTo=${record.prSentTo||''}&from="Sales"
        `;
        history.push({
            pathname: '/AgedMeasures/GetAgedMeasuresRanMWarrantyCashGen',
            search: searchQuery
        });
    };
    const monthsActionHandler = (field) => (value, record) => {
        const spn = document.createElement('span');
        const lnk = (
            <Grid
                title={field.toolTipLabel ? record[field.toolTipLabel] : value}
                onClick={() => redirectToScreens(value, record, props.MeasureID, field)}
                style={{ cursor: 'pointer' }}
            >
                {addCommas(value)}
            </Grid>
        );
        ReactDOM.render(lnk, spn);
        return spn;
    };
    const salesExecActionHandler = (field) => (value, record) => {
        const spn = document.createElement('span');
        const lnk = (
            <Grid
                title={field.toolTipLabel ? record[field.toolTipLabel] : value}
                onClick={() => redirectToScreens(value, record, props.MeasureID, field)}
                style={{ cursor: 'pointer' }}
            >
                {value}
            </Grid>
        );
        ReactDOM.render(lnk, spn);
        return spn;
    };
    const columns = useMemo(() => {
        const tempCols = [...baseColumns];
        let salesExecCol = tempCols.find((element) => element.field === 'salesExec');
        let currentMonthTotalCol = tempCols.find((element) => element.field === 'currentMonthTotal');
        let oneMonthTotalCol = tempCols.find((element) => element.field === 'oneMonthTotal');
        let twoMonthTotalCol = tempCols.find((element) => element.field === 'twoMonthsTotal');
        let threeMonthTotalCol = tempCols.find((element) => element.field === 'threeMonthsTotal');
        let fourPlusMonthTotalCol = tempCols.find((element) => element.field === 'fourMonthsAndOverTotal');
        let unallocatedTotalCol = tempCols.find((element) => element.field === 'unallocatedItemsTotal');
        if (salesExecCol) {
            salesExecCol.renderer = salesExecActionHandler({ MeasureID: 6 });
        }
        if (currentMonthTotalCol) {
            currentMonthTotalCol.renderer = monthsActionHandler({ month: 0, toolTipLabel: 'currentMonthCount' });
        }
        if (oneMonthTotalCol) {
            oneMonthTotalCol.renderer = monthsActionHandler({ month: 1, toolTipLabel: 'oneMonthCount' });
        }
        if (twoMonthTotalCol) {
            twoMonthTotalCol.renderer = monthsActionHandler({ month: 2, toolTipLabel: 'twoMonthsCount' });
        }
        if (threeMonthTotalCol) {
            threeMonthTotalCol.renderer = monthsActionHandler({ month: 3, toolTipLabel: 'threeMonthsCount' });
        }
        if (fourPlusMonthTotalCol) {
            fourPlusMonthTotalCol.renderer = monthsActionHandler({ month: 4, toolTipLabel: 'fourMonthsAndOverCount' });
        }
        if (unallocatedTotalCol) {
            unallocatedTotalCol.renderer = monthsActionHandler({ unallocated: 1, toolTipLabel: 'unallocatedItemsCount' });
        }
        return tempCols;
    }, [props.MeasureID]);
    const baseUrl = `Sales/AccountQueriesSalesexec`;

    return (
        <div className="report-screen-container">
            <BreadCrumbs crumbs={crumbs()} />
            <Grid item container spacing={1} xs={12}>
                <Grid item xs={12}>
                    <ReportGijgoGrid
                        dataConverter={(res) => ({
                            records: res?.data?.list,
                            total: res?.data?.total
                        })}
                        columns={columns}
                        getUrl={baseUrl}
                        isShowTotal={true}
                        hidePagination={true}
                        hideFilterRow={true}
                    />
                </Grid>
            </Grid>
        </div>
    );
};

export default AccountQueriesbySalesexec;
