import React, { useEffect, useState, useContext } from 'react';
import { InputLabel } from '@material-ui/core';
import BreadCrumbs from '../../../Core/Controls/Breadcrumb';
import { DefaultButton, SecondaryButton, TextBox, SelectBox, YesNoButton } from '../../../Core/FormInput';
import { Typography, Container } from '@material-ui/core';
import { SketchPicker } from 'react-color';
import Grid from '@material-ui/core/Grid';
import FormLabel from '@material-ui/core/FormLabel';
import { useHistory } from 'react-router-dom';
import { getPortalSettings, addPortalSettings } from '../../../Core/Service/Settings';
import { getWorkflows } from '../../../Core/Service/workflow-service';
import CustomeEditor from '../../../Core/CkEditor';
import './style.scss';
import AppContext from '../../../App/AppContext';
import TabView from '../../../Core/Controls/Tabs';
import { FormControlLabel } from '@material-ui/core';
import { SecondaryCheckbox } from '../../../Core/FormInput';

const crumbs = [
    { name: 'Home', path: '/' },
    { name: 'Settings', path: '/', active: true }
];

const Settings = (props) => {
    const [state, setState] = useState({
        Title: '',
        CallUs: '',
        OpeningTimes: '',
        EmailUs: '',
        Diag: '',
        Qual: '',
        HeaderTextColorCode: '',
        HeaderBackgroundColorCode: '',
        MenubarTextColorCode: '',
        MenubarBackgroundColorCode: '',
        portalSettingTechnicianDeclaration: null,
        productLogo: '',
        labelLogo: '',
        bannerLogo: '',
        navigationMenuActive: '',
        bannerImage: '',
        Id: '',
        workflowStepOptionsAsButtonGroup: false,
        portalSettingUnassignedTechnicianJobWorkflowID: null
        // portalSettingeWIPEnabled: false,
        // portalSettingReportingEnabled: false
    });
    const { showToast, portalSettings } = useContext(AppContext);

    const history = useHistory();

    const pullSettingsAndUpdateState = async () => {
        let res = await getPortalSettings();
        const data = res?.data?.details[0];
        if (res.success) {
            setState((st) => ({
                ...st,
                Id: data.portalSettingId,
                Title: data.portalSettingTitle,
                CallUs: data.portalSettingCallUs,
                OpeningTimes: data.portalSettingOpeningTime,
                EmailUs: data.portalSettingEmailUs,
                Diag: data.portalSettingDiagnosticCharges || '',
                Qual: data.portalSettingQCMessage || '',
                HeaderTextColorCode: data.portalHeaderTextColour,
                HeaderBackgroundColorCode: data.portalHeaderBackgroundColour,
                MenubarTextColorCode: data.portalMenuBarTextColour,
                MenubarBackgroundColorCode: data.portalMenuBarBackgroundColour,
                navigationMenuActive: data.portalSettingDisplayTopNavCounters,
                workflowStepOptionsAsButtonGroup: data.workflowStepOptionsAsButtonGroup,
                productLogo: data.portalSettingMainLogo,
                bannerLogo: data.portalSettingBanner,
                labelLogo: data.portalSettingAdditionalLogo,
                bannerImage: data.portalSettingBannerImage,
                portalSettingTechnicianDeclaration: data.portalSettingTechnicianDeclaration,
                portalSettingGridRefreshTime: data.portalSettingGridRefreshTime,
                portalSettingTechnWriteupHeight: data.portalSettingTechnWriteupHeight,
                portalSettingDisplayPartStockStatus: data.portalSettingDisplayPartStockStatus,

                portalSettingGridActionLabel: data.portalSettingGridActionLabel,
                portalSettingGridWIPNoLabel: data.portalSettingGridWIPNoLabel,
                portalSettingGridVehicleRegLabel: data.portalSettingGridVehicleRegLabel,
                portalSettingGridModelLabel: data.portalSettingGridModelLabel,
                portalSettingGridCustomerNameLabel: data.portalSettingGridCustomerNameLabel,
                portalSettingGridDateDueInLabel: data.portalSettingGridDateDueInLabel,
                portalSettingGridDateDueOutLabel: data.portalSettingGridDateDueOutLabel,
                portalSettingGridReturnReasonLabel: data.portalSettingGridReturnReasonLabel,
                portalSettingGridBranchLabel: data.portalSettingGridBranchLabel,
                portalSettingGridFranchiseLabel: data.portalSettingGridFranchiseLabel,
                portalSettingGridJobDescriptionLabel: data.portalSettingGridJobDescriptionLabel,
                portalSettingGridAccountLabel: data.portalSettingGridAccountLabel,
                portalSettingGridCreatedByLabel: data.portalSettingGridCreatedByLabel,
                portalSettingGridAssignedDateLabel: data.portalSettingGridAssignedDateLabel,
                portalSettingGridTimeTakenLabel: data.portalSettingGridTimeTakenLabel,
                portalSettingGridStatusLabel: data.portalSettingGridStatusLabel,
                portalSettingGridAssignedDaysLabel: data.portalSettingGridAssignedDaysLabel,
                portalSettingGridChassisNumberLabel: data.portalSettingGridChassisNumberLabel,
                portalSettingGridOwningOperatorLabel: data.portalSettingGridOwningOperatorLabel,
                portalSettingUnassignedTechnicianJobWorkflowID: data.portalSettingUnassignedTechnicianJobWorkflowID,
                // portalSettingeWIPEnabled: data.portalSettingeWIPEnabled,
                // portalSettingReportingEnabled: data.portalSettingReportingEnabled
                portalSettingSpecialEquipmentEnabled: data.portalSettingSpecialEquipmentEnabled,
                showRegNumberInCustomerRatingBox: data.showRegNumberInCustomerRatingBox,

                canCustomerViewNotesButton: data.canCustomerViewNotesButton,
                canCustomerViewHistoryButton: data.canCustomerViewHistoryButton,
                canCustomerViewSaveButton: data.canCustomerViewSaveButton,
                canCustomerViewSaveAndCompleteButton: data.canCustomerViewSaveAndCompleteButton,
                canCustomerViewReturnButton: data.canCustomerViewReturnButton,
                canCustomerViewReturnReasonHistoryButton: data.canCustomerViewReturnReasonHistoryButton,
                canCustomerViewAllDocumentsButton: data.canCustomerViewAllDocumentsButton,
                canAuditorViewNotesButton: data.canAuditorViewNotesButton,
                canAuditorViewHistoryButton: data.canAuditorViewHistoryButton,
                canAuditorViewSaveButton: data.canAuditorViewSaveButton,
                canAuditorViewSaveAndCompleteButton: data.canAuditorViewSaveAndCompleteButton,
                canAuditorViewReturnButton: data.canAuditorViewReturnButton,
                canAuditorViewReturnReasonHistoryButton: data.canAuditorViewReturnReasonHistoryButton,
                canAuditorViewAllDocumentsButton: data.canAuditorViewAllDocumentsButton
            }));
        }
        if (!res.success) {
            console.log(res.message);
        }
    };
    let handleImage = (bannerLogo) => (event) => {
        let reader = new FileReader();
        reader.onload = (e) => {
            const { result } = e.target;
            setState((st) => ({
                ...st,
                [bannerLogo]: result
            }));
        };
        reader.readAsDataURL(event.target.files[0]);
    };

    const clearImage = (name) => (e) => {
        setState((st) => ({ ...st, [name]: null }));
    };

    const modelgridStyle = {
        formatterImageContainer: { width: '100%', textAlign: 'left' },
        //  formatterImage: { width: '40%', maxHeight: 60, backgroundSize: 'cover' },
        formatterImage: { width: 120, maxHeight: 60, backgroundSize: 'cover' }
    };

    const handleClick = (name, value) => {
        setState((st) => {
            const nst = { ...st, [name]: value };
            return nst;
        });
    };

    const handleFieldChange = (e) => {
        const { name, value } = e.target;
        setState((st) => ({ ...st, [name]: value }));
    };

    const handleSubmit = async () => {
        const data = {
            PortalSettingId: state.Id,
            PortalSettingCallUs: state.CallUs,
            PortalSettingOpeningTime: state.OpeningTimes,
            PortalSettingEmailUs: state.EmailUs,
            PortalSettingTitle: state.Title,
            PortalHeaderBackgroundColour: state.HeaderBackgroundColorCode,
            PortalHeaderTextColour: state.HeaderTextColorCode,
            PortalMenuBarBackgroundColour: state.MenubarBackgroundColorCode,
            PortalMenuBarTextColour: state.MenubarTextColorCode,
            PortalSettingBannerImage: state.bannerImage,
            PortalSettingTechnicianDeclaration: state.portalSettingTechnicianDeclaration,
            PortalSettingQCMessage: state.Qual,
            PortalSettingDisplayTopNavCounters: state.navigationMenuActive,
            workflowStepOptionsAsButtonGroup: state.workflowStepOptionsAsButtonGroup,
            PortalSettingMainLogo: state.productLogo,
            PortalSettingBanner: state.bannerLogo,
            PortalSettingAdditionalLogo: state.labelLogo,
            PortalSettingDiagnosticCharges: state.Diag,
            portalSettingGridRefreshTime: state.portalSettingGridRefreshTime,
            portalSettingTechnWriteupHeight: state.portalSettingTechnWriteupHeight,
            portalSettingDisplayPartStockStatus: state.portalSettingDisplayPartStockStatus,

            portalSettingGridActionLabel: state.portalSettingGridActionLabel || '',
            portalSettingGridWIPNoLabel: state.portalSettingGridWIPNoLabel || '',
            portalSettingGridVehicleRegLabel: state.portalSettingGridVehicleRegLabel || '',
            portalSettingGridModelLabel: state.portalSettingGridModelLabel || '',
            portalSettingGridCustomerNameLabel: state.portalSettingGridCustomerNameLabel || '',
            portalSettingGridDateDueInLabel: state.portalSettingGridDateDueInLabel || '',
            portalSettingGridDateDueOutLabel: state.portalSettingGridDateDueOutLabel || '',
            portalSettingGridReturnReasonLabel: state.portalSettingGridReturnReasonLabel || '',
            portalSettingGridBranchLabel: state.portalSettingGridBranchLabel || '',
            portalSettingGridFranchiseLabel: state.portalSettingGridFranchiseLabel || '',
            portalSettingGridJobDescriptionLabel: state.portalSettingGridJobDescriptionLabel || '',
            portalSettingGridAccountLabel: state.portalSettingGridAccountLabel || '',
            portalSettingGridCreatedByLabel: state.portalSettingGridCreatedByLabel || '',
            portalSettingGridAssignedDateLabel: state.portalSettingGridAssignedDateLabel || '',
            portalSettingGridTimeTakenLabel: state.portalSettingGridTimeTakenLabel || '',
            portalSettingGridStatusLabel: state.portalSettingGridStatusLabel || '',
            portalSettingGridAssignedDaysLabel: state.portalSettingGridAssignedDaysLabel || '',
            portalSettingGridChassisNumberLabel: state.portalSettingGridChassisNumberLabel || '',
            portalSettingGridOwningOperatorLabel: state.portalSettingGridOwningOperatorLabel || '',
            portalSettingUnassignedTechnicianJobWorkflowID: state.portalSettingUnassignedTechnicianJobWorkflowID,
            // portalSettingeWIPEnabled: state.portalSettingeWIPEnabled,
            // portalSettingReportingEnabled: state.portalSettingReportingEnabled
            portalSettingSpecialEquipmentEnabled: state.portalSettingSpecialEquipmentEnabled,
            showRegNumberInCustomerRatingBox: state.showRegNumberInCustomerRatingBox,

            canCustomerViewNotesButton: state.canCustomerViewNotesButton || false,
            canCustomerViewHistoryButton: state.canCustomerViewHistoryButton || false,
            canCustomerViewSaveButton: state.canCustomerViewSaveButton || false,
            canCustomerViewSaveAndCompleteButton: state.canCustomerViewSaveAndCompleteButton || false,
            canCustomerViewReturnButton: state.canCustomerViewReturnButton || false,
            canCustomerViewReturnReasonHistoryButton: state.canCustomerViewReturnReasonHistoryButton || false,
            canCustomerViewAllDocumentsButton: state.canCustomerViewAllDocumentsButton || false,
            canAuditorViewNotesButton: state.canAuditorViewNotesButton || false,
            canAuditorViewHistoryButton: state.canAuditorViewHistoryButton || false,
            canAuditorViewSaveButton: state.canAuditorViewSaveButton || false,
            canAuditorViewSaveAndCompleteButton: state.canAuditorViewSaveAndCompleteButton || false,
            canAuditorViewReturnButton: state.canAuditorViewReturnButton || false,
            canAuditorViewReturnReasonHistoryButton: state.canAuditorViewReturnReasonHistoryButton || false,
            canAuditorViewAllDocumentsButton: state.canAuditorViewAllDocumentsButton || false
        };

        let res = await addPortalSettings(data);
        if (res.success) {
            showToast('Portal setting saved successfully');
            props.history.push('/');
        } else {
            showToast(res.message);
        }
    };

    useEffect(async () => {
        pullSettingsAndUpdateState();
        let result1 = await getWorkflows();
        setState((st) => ({
            ...st,
            workFlowList: result1.data.map((m) => ({ id: m.id, name: m.workflowName }))
        }));
    }, []);

    const handleCkeditorstate = (name) => (event, editor) => {
        const data = editor.getData();
        setState((st) => ({ ...st, [name]: data }));
    };

    const handleCheckbox = (e, a) => {
        setState((st) => ({ ...st, [e.target.name]: a }));
    };

    const EwipModule = () => {
        return (
            <div style={{ padding: '20px 20px' }}>
                <Grid container spacing={2}>
                    <Grid xs={12} sm={6} md={6} lg={3} container item>
                        <Grid item xs={12} style={{ marginBottom: '5px' }}>
                            <FormLabel>Hide Work Accomplished and Failure Reasons?</FormLabel>
                        </Grid>
                        <YesNoButton
                            state={state.workflowStepOptionsAsButtonGroup}
                            name="workflowStepOptionsAsButtonGroup"
                            onYesClick={() => handleClick('workflowStepOptionsAsButtonGroup', true)}
                            onNoClick={() => handleClick('workflowStepOptionsAsButtonGroup', false)}
                        />
                    </Grid>
                    <Grid xs={12} sm={6} md={6} lg={3} container item>
                        <Grid item xs={12} style={{ marginBottom: '5px' }}>
                            <FormLabel>Display Part Stock Status?</FormLabel>
                        </Grid>
                        <YesNoButton
                            state={state.portalSettingDisplayPartStockStatus}
                            name="portalSettingDisplayPartStockStatus"
                            onYesClick={() => handleClick('portalSettingDisplayPartStockStatus', true)}
                            onNoClick={() => handleClick('portalSettingDisplayPartStockStatus', false)}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} md={6} lg={3} container>
                        <Grid item xs={12} style={{ marginBottom: '5px' }}>
                            <FormLabel shrink>Workflow - unassigned technician job</FormLabel>
                        </Grid>
                        <SelectBox
                            onChange={handleFieldChange}
                            value={state.portalSettingUnassignedTechnicianJobWorkflowID}
                            name="portalSettingUnassignedTechnicianJobWorkflowID"
                            List={state.workFlowList || []}
                        />
                    </Grid>
                    <Grid container item xs={12} sm={6} lg={3}>
                        <Grid xs={12} sm={6} md={7} item style={{ paddingRight: '15px' }}>
                            <FormLabel> Grid Refresh Time (seconds)</FormLabel>
                            <TextBox
                                type="number"
                                value={state.portalSettingGridRefreshTime}
                                onChange={handleFieldChange}
                                name="portalSettingGridRefreshTime"
                            />
                        </Grid>
                        <Grid xs={12} sm={6} md={5} item>
                            <FormLabel> Tech Writeup Height</FormLabel>
                            <TextBox
                                type="number"
                                value={state.portalSettingTechnWriteupHeight}
                                onChange={handleFieldChange}
                                name="portalSettingTechnWriteupHeight"
                            />
                        </Grid>
                    </Grid>

                    <Grid xs={12} sm={6} md={6} lg={3} container item>
                        <Grid item xs={12} style={{ marginBottom: '5px' }}>
                            <FormLabel>Special Equipment Enabled?</FormLabel>
                        </Grid>
                        <YesNoButton
                            state={state.portalSettingSpecialEquipmentEnabled}
                            name="portalSettingSpecialEquipmentEnabled"
                            onYesClick={() => handleClick('portalSettingSpecialEquipmentEnabled', true)}
                            onNoClick={() => handleClick('portalSettingSpecialEquipmentEnabled', false)}
                        />
                    </Grid>

                    <Grid xs={12} sm={6} md={6} lg={3} container item>
                        <Grid item xs={12} style={{ marginBottom: '5px' }}>
                            <FormLabel>Show Reg In Customer Rating Box?</FormLabel>
                        </Grid>
                        <YesNoButton
                            state={state.showRegNumberInCustomerRatingBox}
                            name="showRegNumberInCustomerRatingBox"
                            onYesClick={() => handleClick('showRegNumberInCustomerRatingBox', true)}
                            onNoClick={() => handleClick('showRegNumberInCustomerRatingBox', false)}
                        />
                    </Grid>

                    <Grid item xs={12} md={12} lg={12} style={{ textAlign: 'left' }}>
                        <Typography variant="body2" color="secondary">
                            Diagnostic Charges
                        </Typography>
                        <hr />
                        <CustomeEditor data={state.Diag} onChange={handleCkeditorstate('Diag')} placeholder="Add description here" name="Diag" />
                    </Grid>
                    <Grid item xs={12} md={12} lg={12} style={{ textAlign: 'left' }}>
                        <Typography variant="body2" color="secondary">
                            Quality Control
                        </Typography>
                        <hr />
                        <CustomeEditor data={state.Qual} onChange={handleCkeditorstate('Qual')} placeholder="Add description here" name="Qual" />
                    </Grid>
                </Grid>
                <Grid container item xs={12} sm={12}>
                    <Grid item xs={12} md={12} lg={12} style={{ top: 0, textAlign: 'left', paddingTop: '15px' }}>
                        <Typography variant="body2" color="secondary">
                            User Grid Columns
                        </Typography>
                        <hr />
                    </Grid>
                    <Grid container justify="space-between" style={{ textAlign: 'left' }} spacing={2}>
                        <Grid item xs={6} sm={4} md={2}>
                            <FormLabel>Action</FormLabel>
                            <TextBox
                                inputLabel="Action"
                                placeholder="Action"
                                value={state.portalSettingGridActionLabel}
                                onChange={handleFieldChange}
                                name="portalSettingGridActionLabel"
                            />
                        </Grid>
                        <Grid item xs={6} sm={4} md={2}>
                            <FormLabel>WIP No</FormLabel>
                            <TextBox
                                inputLabel="WIPNo"
                                placeholder="WIP No"
                                value={state.portalSettingGridWIPNoLabel}
                                onChange={handleFieldChange}
                                name="portalSettingGridWIPNoLabel"
                            />
                        </Grid>
                        <Grid item xs={6} sm={4} md={2}>
                            <FormLabel>Vehicle Reg</FormLabel>
                            <TextBox
                                inputLabel="VehicleReg"
                                placeholder="Vehicle Reg"
                                value={state.portalSettingGridVehicleRegLabel}
                                onChange={handleFieldChange}
                                name="portalSettingGridVehicleRegLabel"
                            />
                        </Grid>
                        <Grid item xs={6} sm={4} md={2}>
                            <FormLabel>Model</FormLabel>
                            <TextBox
                                inputLabel="Model"
                                placeholder="Model"
                                value={state.portalSettingGridModelLabel}
                                onChange={handleFieldChange}
                                name="portalSettingGridModelLabel"
                            />
                        </Grid>
                        <Grid item xs={6} sm={4} md={2}>
                            <FormLabel>Customer Name</FormLabel>
                            <TextBox
                                inputLabel="CustomerName"
                                placeholder="Customer Name"
                                value={state.portalSettingGridCustomerNameLabel}
                                onChange={handleFieldChange}
                                name="portalSettingGridCustomerNameLabel"
                            />
                        </Grid>
                        <Grid item xs={6} sm={4} md={2}>
                            <FormLabel>Date Due In</FormLabel>
                            <TextBox
                                inputLabel="DateDueIn"
                                placeholder="Date Due In"
                                value={state.portalSettingGridDateDueInLabel}
                                onChange={handleFieldChange}
                                name="portalSettingGridDateDueInLabel"
                            />
                        </Grid>
                        <Grid item xs={6} sm={4} md={2}>
                            <FormLabel>Date Due Out</FormLabel>
                            <TextBox
                                inputLabel="DateDueOut"
                                placeholder="Date Due Out"
                                value={state.portalSettingGridDateDueOutLabel}
                                onChange={handleFieldChange}
                                name="portalSettingGridDateDueOutLabel"
                            />
                        </Grid>
                        <Grid item xs={6} sm={4} md={2}>
                            <FormLabel>Return Reason</FormLabel>
                            <TextBox
                                inputLabel="ReturnReason"
                                placeholder="Return Reason"
                                value={state.portalSettingGridReturnReasonLabel}
                                onChange={handleFieldChange}
                                name="portalSettingGridReturnReasonLabel"
                            />
                        </Grid>
                        <Grid item xs={6} sm={4} md={2}>
                            <FormLabel>Branch</FormLabel>
                            <TextBox
                                inputLabel="Branch"
                                placeholder="Branch"
                                value={state.portalSettingGridBranchLabel}
                                onChange={handleFieldChange}
                                name="portalSettingGridBranchLabel"
                            />
                        </Grid>
                        <Grid item xs={6} sm={4} md={2}>
                            <FormLabel>Franchise</FormLabel>
                            <TextBox
                                inputLabel="Franchise"
                                placeholder="Franchise"
                                value={state.portalSettingGridFranchiseLabel}
                                onChange={handleFieldChange}
                                name="portalSettingGridFranchiseLabel"
                            />
                        </Grid>
                        <Grid item xs={6} sm={4} md={2}>
                            <FormLabel>Job Description</FormLabel>
                            <TextBox
                                inputLabel="JobDescription"
                                placeholder="Job Description"
                                value={state.portalSettingGridJobDescriptionLabel}
                                onChange={handleFieldChange}
                                name="portalSettingGridJobDescriptionLabel"
                            />
                        </Grid>
                        <Grid item xs={6} sm={4} md={2}>
                            <FormLabel>Account</FormLabel>
                            <TextBox
                                inputLabel="Account"
                                placeholder="Account"
                                value={state.portalSettingGridAccountLabel}
                                onChange={handleFieldChange}
                                name="portalSettingGridAccountLabel"
                            />
                        </Grid>
                        <Grid item xs={6} sm={4} md={2}>
                            <FormLabel>Created By</FormLabel>
                            <TextBox
                                inputLabel="CreatedBy"
                                placeholder="Created By"
                                value={state.portalSettingGridCreatedByLabel}
                                onChange={handleFieldChange}
                                name="portalSettingGridCreatedByLabel"
                            />
                        </Grid>
                        <Grid item xs={6} sm={4} md={2}>
                            <FormLabel>Assigned Date</FormLabel>
                            <TextBox
                                inputLabel="AssignedDate"
                                placeholder="Assigned Date"
                                value={state.portalSettingGridAssignedDateLabel}
                                onChange={handleFieldChange}
                                name="portalSettingGridAssignedDateLabel"
                            />
                        </Grid>
                        <Grid item xs={6} sm={4} md={2}>
                            <FormLabel>Time Taken</FormLabel>
                            <TextBox
                                inputLabel="TimeTaken"
                                placeholder="Time Taken"
                                value={state.portalSettingGridTimeTakenLabel}
                                onChange={handleFieldChange}
                                name="portalSettingGridTimeTakenLabel"
                            />
                        </Grid>
                        <Grid item xs={6} sm={4} md={2}>
                            <FormLabel>Status</FormLabel>
                            <TextBox
                                inputLabel="Status"
                                placeholder="Status"
                                value={state.portalSettingGridStatusLabel}
                                onChange={handleFieldChange}
                                name="portalSettingGridStatusLabel"
                            />
                        </Grid>
                        <Grid item xs={6} sm={4} md={2}>
                            <FormLabel>Assigned Days</FormLabel>
                            <TextBox
                                inputLabel="AssignedDays"
                                placeholder="Assigned Days"
                                value={state.portalSettingGridAssignedDaysLabel}
                                onChange={handleFieldChange}
                                name="portalSettingGridAssignedDaysLabel"
                            />
                        </Grid>
                        <Grid item xs={6} sm={4} md={2}>
                            <FormLabel>Chassis Number</FormLabel>
                            <TextBox
                                inputLabel="ChassisNumber"
                                placeholder="Chassis Number"
                                value={state.portalSettingGridChassisNumberLabel}
                                onChange={handleFieldChange}
                                name="portalSettingGridChassisNumberLabel"
                            />
                        </Grid>
                        <Grid item xs={6} sm={4} md={2}>
                            <FormLabel>Owning Operator</FormLabel>
                            <TextBox
                                inputLabel="OwningOperator"
                                placeholder="Owning Operator"
                                value={state.portalSettingGridOwningOperatorLabel}
                                onChange={handleFieldChange}
                                name="portalSettingGridOwningOperatorLabel"
                            />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid container spacing={2} item xs={12} sm={12} style={{ textAlign: 'left' }}>
                    <Grid item xs={12} md={12} lg={12} style={{ top: 0, textAlign: 'left', paddingTop: '15px' }}>
                        <Typography variant="body2" color="secondary">
                            Workflow Button Visibility
                        </Typography>
                        <hr />
                    </Grid>
                    <Grid item xs={12} md={12} lg={12} style={{ top: 0, textAlign: 'left', marginTop: '-15px' }}>
                        <Typography variant="body2" color="primary" style={{ fontWeight: 'bold' }}>
                            Customer
                        </Typography>
                    </Grid>
                    <Grid item xs={12} sm={6} md={6} lg={2}>
                        <FormControlLabel
                            control={
                                <SecondaryCheckbox checked={state.canCustomerViewNotesButton} onChange={handleCheckbox} name="canCustomerViewNotesButton" />
                            }
                            label="Show Notes Button?"
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} md={6} lg={2}>
                        <FormControlLabel
                            control={
                                <SecondaryCheckbox checked={state.canCustomerViewHistoryButton} onChange={handleCheckbox} name="canCustomerViewHistoryButton" />
                            }
                            label="Show History Button?"
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} md={6} lg={2}>
                        <FormControlLabel
                            control={<SecondaryCheckbox checked={state.canCustomerViewSaveButton} onChange={handleCheckbox} name="canCustomerViewSaveButton" />}
                            label="Show Save Button?"
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} md={6} lg={2}>
                        <FormControlLabel
                            control={
                                <SecondaryCheckbox
                                    checked={state.canCustomerViewSaveAndCompleteButton}
                                    onChange={handleCheckbox}
                                    name="canCustomerViewSaveAndCompleteButton"
                                />
                            }
                            label="Show Save & Complete Button?"
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} md={6} lg={2}>
                        <FormControlLabel
                            control={
                                <SecondaryCheckbox checked={state.canCustomerViewReturnButton} onChange={handleCheckbox} name="canCustomerViewReturnButton" />
                            }
                            label="Show Return Button?"
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} md={6} lg={2}>
                        <FormControlLabel
                            control={
                                <SecondaryCheckbox
                                    checked={state.canCustomerViewReturnReasonHistoryButton}
                                    onChange={handleCheckbox}
                                    name="canCustomerViewReturnReasonHistoryButton"
                                />
                            }
                            label="Show Return History Button?"
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} md={6} lg={2}>
                        <FormControlLabel
                            control={
                                <SecondaryCheckbox
                                    checked={state.canCustomerViewAllDocumentsButton}
                                    onChange={handleCheckbox}
                                    name="canCustomerViewAllDocumentsButton"
                                />
                            }
                            label="Show All Documents Button?"
                        />
                    </Grid>

                    <Grid item xs={12} md={12} lg={12} style={{ top: 0, textAlign: 'left' }}>
                        <Typography variant="body2" color="primary" style={{ fontWeight: 'bold' }}>
                            Auditor
                        </Typography>
                    </Grid>
                    <Grid item xs={12} sm={6} md={6} lg={2}>
                        <FormControlLabel
                            control={<SecondaryCheckbox checked={state.canAuditorViewNotesButton} onChange={handleCheckbox} name="canAuditorViewNotesButton" />}
                            label="Show Notes Button?"
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} md={6} lg={2}>
                        <FormControlLabel
                            control={
                                <SecondaryCheckbox checked={state.canAuditorViewHistoryButton} onChange={handleCheckbox} name="canAuditorViewHistoryButton" />
                            }
                            label="Show History Button?"
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} md={6} lg={2}>
                        <FormControlLabel
                            control={<SecondaryCheckbox checked={state.canAuditorViewSaveButton} onChange={handleCheckbox} name="canAuditorViewSaveButton" />}
                            label="Show Save Button?"
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} md={6} lg={2}>
                        <FormControlLabel
                            control={
                                <SecondaryCheckbox
                                    checked={state.canAuditorViewSaveAndCompleteButton}
                                    onChange={handleCheckbox}
                                    name="canAuditorViewSaveAndCompleteButton"
                                />
                            }
                            label="Show Save & Complete Button?"
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} md={6} lg={2}>
                        <FormControlLabel
                            control={
                                <SecondaryCheckbox checked={state.canAuditorViewReturnButton} onChange={handleCheckbox} name="canAuditorViewReturnButton" />
                            }
                            label="Show Return Button?"
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} md={6} lg={2}>
                        <FormControlLabel
                            control={
                                <SecondaryCheckbox
                                    checked={state.canAuditorViewReturnReasonHistoryButton}
                                    onChange={handleCheckbox}
                                    name="canAuditorViewReturnReasonHistoryButton"
                                />
                            }
                            label="Show Return History Button?"
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} md={6} lg={2}>
                        <FormControlLabel
                            control={
                                <SecondaryCheckbox
                                    checked={state.canAuditorViewAllDocumentsButton}
                                    onChange={handleCheckbox}
                                    name="canAuditorViewAllDocumentsButton"
                                />
                            }
                            label="Show All Documents Button?"
                        />
                    </Grid>
                </Grid>
            </div>
        );
    };
    const tabs = [
        ...(portalSettings.portalSettingeWIPEnabled
            ? [
                  {
                      label: 'eWIP',
                      body: <EwipModule />
                  }
              ]
            : [])
        // ...(portalSettings.portalSettingReportingEnabled
        //     ? [
        //           {
        //               label: 'Reporting',
        //               body: <></>
        //           }
        //       ]
        //     : null)
    ];
    return (
        <div className="setting-screen" style={{ margin: 10 }}>
            <BreadCrumbs crumbs={crumbs} />
            <Grid container spacing={2}>
                <Grid item xs={12} sm={6} md={3}>
                    <FormLabel>Portal Title</FormLabel>
                    <TextBox inputLabel="Portal Title" placeholder="Portal Title" value={state.Title} onChange={handleFieldChange} name="Title" />
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                    <FormLabel>Call Us</FormLabel>
                    <TextBox inputLabel="Call Us" placeholder="Number" value={state.CallUs} onChange={handleFieldChange} name="CallUs" />
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                    <FormLabel>Opening Times</FormLabel>
                    <TextBox
                        inputLabel="Opening Times"
                        placeholder="Opening Times"
                        value={state.OpeningTimes}
                        onChange={handleFieldChange}
                        name="OpeningTimes"
                    />
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                    <FormLabel>Email Us</FormLabel>
                    <TextBox inputLabel="Email us" placeholder="Email us" value={state.EmailUs} onChange={handleFieldChange} name="EmailUs" />
                </Grid>
            </Grid>
            <Grid item container spacing={2}>
                <Grid item xs={12} md={12} lg={12} style={{ textAlign: 'left' }}>
                    <Typography variant="h5" color="secondary">
                        Colors
                    </Typography>
                    <hr />
                </Grid>
            </Grid>
            <Grid container spacing={0} justify="space-around" style={{ textAlign: 'left' }}>
                <Grid item container spacing={4} xs={12} md={3} sm={6}>
                    <Grid item xs={12} sm={12}>
                        <FormLabel component="legend">
                            <b>Header Text</b>
                        </FormLabel>
                    </Grid>
                    <Grid item xs={12} md={3} sm={6}>
                        <SketchPicker
                            color={state.HeaderTextColorCode}
                            onChange={(updatedColor) =>
                                setState((state) => ({
                                    ...state,
                                    HeaderTextColorCode: updatedColor.hex
                                }))
                            }
                        />
                    </Grid>
                </Grid>
                <Grid item container spacing={4} xs={12} md={3} sm={6}>
                    <Grid item xs={12} sm={12}>
                        <FormLabel component="legend">
                            <b>Header Background</b>
                        </FormLabel>
                    </Grid>
                    <Grid item xs={12} md={3} sm={6}>
                        <SketchPicker
                            color={state.HeaderBackgroundColorCode}
                            onChange={(updatedColor) =>
                                setState((state) => ({
                                    ...state,
                                    HeaderBackgroundColorCode: updatedColor.hex
                                }))
                            }
                        />
                    </Grid>
                </Grid>
                <Grid item container spacing={4} xs={12} md={3} sm={6}>
                    <Grid item xs={12} sm={12}>
                        <FormLabel component="legend">
                            <b>Menu bar Text</b>
                        </FormLabel>
                    </Grid>
                    <Grid item xs={12} md={3} sm={6}>
                        <SketchPicker
                            color={state.MenubarTextColorCode}
                            onChange={(updatedColor) =>
                                setState((state) => ({
                                    ...state,
                                    MenubarTextColorCode: updatedColor.hex
                                }))
                            }
                        />
                    </Grid>
                </Grid>

                <Grid item container spacing={4} xs={12} md={3} sm={6}>
                    <Grid item xs={12} sm={12}>
                        <FormLabel component="legend">
                            <b>Menu bar Background</b>
                        </FormLabel>
                    </Grid>
                    <Grid item xs={12} md={3} sm={6}>
                        <SketchPicker
                            color={state.MenubarBackgroundColorCode}
                            onChange={(updatedColor) =>
                                setState((state) => ({
                                    ...state,
                                    MenubarBackgroundColorCode: updatedColor.hex
                                }))
                            }
                        />
                    </Grid>
                </Grid>
            </Grid>
            <Grid container spacing={2} item xs={12} sm={12} style={{ textAlign: 'left', paddingTop: '15px' }}>
                <Grid item xs={12} md={12} lg={12}>
                    <Typography variant="h5" color="secondary">
                        Logos
                    </Typography>
                    <hr />
                </Grid>
                <Grid item xs={12} lg={3} sm={3} style={{ textAlign: 'left' }}>
                    <InputLabel shrink>Product Logo</InputLabel>
                    {state.productLogo ? (
                        <SecondaryButton onClick={clearImage('productLogo')}>Remove Logo</SecondaryButton>
                    ) : (
                        <input type="file" name="productLogo" onChange={handleImage('productLogo')} accept="image/*" />
                    )}
                </Grid>
                <Grid item xs={12} sm={3} lg={3} style={modelgridStyle.formatterImageContainer}>
                    <InputLabel shrink>{'\u{2800}'}</InputLabel>
                    {state.productLogo ? (
                        <img
                            src={state.productLogo?.includes('base64') ? state.productLogo : 'data:image/png;base64,' + state.productLogo}
                            alt={'logo'}
                            style={modelgridStyle.formatterImage}
                        />
                    ) : (
                        <p> Using default logo</p>
                    )}
                </Grid>
                <Grid item xs={12} lg={3} sm={3} style={{ textAlign: 'left' }}>
                    <InputLabel shrink style={{ paddingRight: '60px' }}>
                        Banner Image
                    </InputLabel>
                    {state.bannerLogo ? (
                        <SecondaryButton onClick={clearImage('bannerLogo')}>Remove Logo</SecondaryButton>
                    ) : (
                        <input type="file" name="bannerLogo" onChange={handleImage('bannerLogo')} accept="image/*" />
                    )}
                </Grid>
                <Grid item xs={12} sm={3} lg={3} style={modelgridStyle.formatterImageContainer}>
                    <InputLabel shrink>{'\u{2800}'}</InputLabel>
                    {state.bannerLogo ? (
                        <img
                            src={state.bannerLogo?.includes('base64') ? state.bannerLogo : 'data:image/png;base64,' + state.bannerLogo}
                            alt={'logo'}
                            style={modelgridStyle.formatterImage}
                        />
                    ) : (
                        <p> Using default logo</p>
                    )}
                </Grid>
            </Grid>
            <Grid container item xs={12} sm={12}>
                <Grid item xs={12} md={12} lg={12} style={{ top: 0, textAlign: 'left' }}>
                    <Typography variant="h5" color="secondary">
                        Settings
                    </Typography>
                    <hr />
                </Grid>
                <Grid container style={{ textAlign: 'left' }} spacing={2}>
                    <Grid xs={12} sm={6} md={3} container item>
                        <Grid item xs={12} style={{ marginBottom: '5px' }}>
                            <FormLabel>Display Top Navigation Counter?</FormLabel>
                        </Grid>
                        <YesNoButton
                            state={state.navigationMenuActive}
                            name="navigationMenuActive"
                            onYesClick={() => handleClick('navigationMenuActive', true)}
                            onNoClick={() => handleClick('navigationMenuActive', false)}
                        />
                    </Grid>
                </Grid>
            </Grid>
            <TabView tabList={tabs} id="testing_tabs" tabLableSize="1.2rem" tabLableWeight="700" />
            <Grid item xs={12} container spacing={2} style={{ marginTop: '10px' }}>
                <Grid item xs={6} className="submit_btn" style={{ textAlign: 'center' }}>
                    <SecondaryButton fullWidth onClick={handleSubmit}>
                        Save
                    </SecondaryButton>
                </Grid>
                <Grid item xs={6} className="submit_btn" style={{ textAlign: 'center' }}>
                    <SecondaryButton
                        fullWidth
                        onClick={() => {
                            props.history.push('/');
                        }}
                    >
                        Cancel
                    </SecondaryButton>
                </Grid>
            </Grid>
        </div>
    );
};

export default Settings;
