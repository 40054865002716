import { Grid, FormLabel, FormControlLabel } from '@material-ui/core';
import React from 'react';
import { YesNoButton, Multiselect, PrimaryCheckbox } from '../../../Core/FormInput';
import AppContext from '../../../App/AppContext';
import { useContext } from 'react';

export default function EwipModules(props) {
    const { handleClick, state, handleCheckbox, selectedData, masterData, AutoWorkChange } = props;
    const { portalSettings } = useContext(AppContext);
    return (
        <div>
            <Grid item xs={12} sm={12}>
                <Grid item xs={12}>
                    <div>
                        {/* workflow-start */}
                        <Grid item xs={12}>
                            <FormControlLabel
                                control={<PrimaryCheckbox onChange={handleCheckbox} checked={state.eWIPAllowed} name="eWIPAllowed" value={state.eWIPAllowed} />}
                                label="eWIP Allowed?"
                            />
                        </Grid>
                        {portalSettings.portalSettingSpecialEquipmentEnabled && (
                            <Grid item xs={12}>
                                <FormControlLabel
                                    control={<PrimaryCheckbox onChange={handleCheckbox} checked={state.specialEquipmentAllowed} name="specialEquipmentAllowed" value={state.specialEquipmentAllowed} />}
                                    label="Special Equipment Allowed?"
                                />
                            </Grid>
                        )}
                        <Grid item xs={12}>
                            <FormControlLabel
                                control={<PrimaryCheckbox onChange={handleCheckbox} checked={state.allworkflow} name="allworkflow" value={state.allworkflow} />}
                                label="All Workflows"
                            />
                        </Grid>
                        {!state.allworkflow ? (
                            <Multiselect label="Workflow" options={masterData.workflows} value={selectedData.workflows} onChange={AutoWorkChange} />
                        ) : null}
                        {/* workflow-end */}
                    </div>
                </Grid>
                <Grid container>
                    <Grid item xs={12} sm={6} md={4}>
                        <Grid item xs={12}>
                            Can Override Mandatory Steps?
                        </Grid>
                        <Grid item xs={10}>
                            <YesNoButton
                                state={state.canOverrideMandatorySteps}
                                name="canOverrideMandatorySteps"
                                onYesClick={() => handleClick('canOverrideMandatorySteps', true)}
                                onNoClick={() => handleClick('canOverrideMandatorySteps', false)}
                            />
                        </Grid>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                        <Grid item xs={12}>
                            Can Assign Jobs to Auditor?
                        </Grid>
                        <Grid item xs={10}>
                            <YesNoButton
                                state={state.canAssignJobsToAuditor}
                                onNoClick={() => handleClick('canAssignJobsToAuditor', false)}
                                onYesClick={() => handleClick('canAssignJobsToAuditor', true)}
                            />
                        </Grid>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                        <Grid item xs={12}>
                            Can Return Jobs From Last Workflow?
                        </Grid>
                        <Grid item xs={10}>
                            <YesNoButton
                                state={state.canReturnJobsFromLastWorkflow}
                                onNoClick={() => handleClick('canReturnJobsFromLastWorkflow', false)}
                                onYesClick={() => handleClick('canReturnJobsFromLastWorkflow', true)}
                            />
                        </Grid>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                        <Grid item xs={12}>
                            Can Delete Documents?
                        </Grid>
                        <Grid item xs={10}>
                            <YesNoButton
                                state={state.canDeleteDocuments}
                                onNoClick={() => handleClick('canDeleteDocuments', false)}
                                onYesClick={() => handleClick('canDeleteDocuments', true)}
                            />
                        </Grid>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                        <Grid item xs={12}>
                            Can Reset Password?
                        </Grid>
                        <Grid item xs={10}>
                            <YesNoButton
                                state={state.canResetPassword}
                                onNoClick={() => handleClick('canResetPassword', false)}
                                onYesClick={() => handleClick('canResetPassword', true)}
                            />
                        </Grid>
                    </Grid>
                    {state.userType == 'internal' && (
                        <Grid item xs={12} sm={6} md={4}>
                            <Grid item xs={12}>
                                Can Assign Job Priority?
                            </Grid>
                            <Grid item xs={10}>
                                <YesNoButton
                                    state={state.canAssignJobPriority}
                                    onNoClick={() => handleClick('canAssignJobPriority', false)}
                                    onYesClick={() => handleClick('canAssignJobPriority', true)}
                                />
                            </Grid>
                        </Grid>
                    )}
                    {state.userType == 'internal' && (
                        <Grid item xs={12} sm={6} md={4}>
                            <Grid item xs={12}>
                                Can Assign Customer Rating?
                            </Grid>
                            <Grid item xs={10}>
                                <YesNoButton
                                    state={state.canSetCustomerRating}
                                    onNoClick={() => handleClick('canSetCustomerRating', false)}
                                    onYesClick={() => handleClick('canSetCustomerRating', true)}
                                />
                            </Grid>
                        </Grid>
                    )}
                    {state.userType == 'internal' && (
                        <Grid item xs={12} sm={6} md={4}>
                            <Grid item xs={12}>
                                Can Assign Technician?
                            </Grid>
                            <Grid item xs={10}>
                                <YesNoButton
                                    state={state.canAssignTechnician}
                                    onNoClick={() => handleClick('canAssignTechnician', false)}
                                    onYesClick={() => handleClick('canAssignTechnician', true)}
                                />
                            </Grid>
                        </Grid>
                    )}
                </Grid>
            </Grid>
        </div>
    );
}
