import React, { useContext, useEffect, useRef, useState } from 'react';
import { ButtonGroup, Grid, IconButton, LinearProgress, Tooltip, Badge, Chip, Avatar } from '@material-ui/core';
import { BasicButtonGroup } from '../../../Core/ButtonGroup';
import WorkflowStep from './WorkflowStep';
import { DefaultButton, PrimaryButton, SecondaryButton } from '../../../Core/FormInput';
import AppContext from '../../../App/AppContext';
import { getDocumentByName } from '../../../Core/Service/workflowJobDocument-service';
import { WorkflowJobDetailsProvider as StateProvider, ScreenContext, MarkingType, FixedImage } from './WorkflowJob.Provider';
import JobNotesModal from './JobNotes';
import WorkflowMovementModal from './WorkflowMovement';
import ReturnReasonWorkflow from './ReturnToWorkflow';
import OcrDocHistory from './WorkflowOcrDocHistoryModal';
import MailDocModal from './more/MailDoc';
import AllDocModal from './more/AllDocModal';
import WarningMsg from './WarningMsg';
import ReturnReasonHistoryModal from './ReturnReasonHistory';
import HistoryIcon from '@material-ui/icons/History';
import doc from './doc.png';
import './workflow-job.scss';
import { Reply, History, Rotate90DegreesCcw, ArrowBack, AlternateEmailOutlined, Explicit, CalendarToday, BorderColor, TextFields } from '@material-ui/icons';
import { SpeakerNotes, Timeline, Done, DoneAll, PhotoLibraryOutlined, Close } from '@material-ui/icons';
import DocStamps from './more/Stamps';
import { getDocumentStamps } from '../../../Core/Service/workflowJobsDocumentStamp-service';
import { ToggleButton, ToggleButtonGroup } from '@material-ui/lab';
import HavePendingMarking from './more/ConfirmationOnPendingMarking.jsx';
import ViewWeekIcon from '@material-ui/icons/ViewWeek';
import ShowBarCode from './BarCode';
import DialogComp from '../../../Core/Controls/Dialog/DialogComp';
import CustomerNotes from './CustomerNotes';
import FeaturedPlayListIcon from '@material-ui/icons/FeaturedPlayList';
import AssignmentIcon from '@material-ui/icons/Assignment';
import AddEditSiteVehicle from '../Communicator/SiteVehicles/addEditSiteVehicle';
import LogHistory from '../Communicator/OpenActionLogs/logHistory';
import BuildIcon from '@material-ui/icons/Build';
import UpdateIcon from '@material-ui/icons/Update';
import { AppStorage } from '../../../Core/Service/storage-service';
import { useHistory } from 'react-router-dom';
import HeaderDetailsModal from './more/headerDetailsModal';
import InfoIcon from '@material-ui/icons/Info';
import { useWindowSize } from '../../../Core/Controls/ScreenResolution';

const WorkflowJobDetails = (props) => {
    const [WindowWidths, WindowHeights] = useWindowSize();
    const docContainer = useRef(null);
    const stampContainer = useRef(null);
    const { showToast, canOverrideMandatorySteps, userRoles, isTopNav } = useContext(AppContext);
    const portalSettings = AppStorage.getPortalSetting();
    const isCommunicatorAllowed = AppStorage.getCommunicatorAllowed() && portalSettings.portalSettingCommunicatorEnabled;
    const {
        screenState,
        updateState,
        state,
        stampState,
        startMarkingMode,
        stopMarkingMode,
        markingTypeSelection,
        saveStampData,
        setLoadedStamps,
        addDocumentImagesData,
        workflowData,
        dropDownValueSelected,
        showNotes,
        hideNotesModal,
        notesAdded,
        showHistory,
        showReturnReasonHistory,
        hideReturnReasonHistory,
        hideHistoryModal,
        showReturnReason,
        hideReturnReason,
        returnProcessed,
        tryToSaveWorkflowData,
        showOcrDocHistoryModal,
        hideOcrDocHistoryModal,
        rotateDoc,
        showMail,
        hideMail,
        showAllDoc,
        hideAllDoc,
        getDocumentFromOcrDocId,
        hideWarningMsg,
        tabView,
        tabSize,
        getDocumentHistory,
        onBarCodeClick,
        onBarCodeClose,
        handleCustomerNotes,
        handleHistoryModal,
        closeHistoryModal,
        handleVehicleStatusClose,
        onVehicleStatusClick,
        showHeaderDetailsHandler,
        closeHeaderDetails
    } = useContext(ScreenContext);
    const { allFilled, isReadonly, documentRotateBy, zoomFactor, docInfoToMail } = screenState;
    const saveBtnEnabled = !isReadonly && (canOverrideMandatorySteps || allFilled);

    const workflowButtonClicked = (btn) => {
        updateState({ selectedWorkflowId: btn.id });
    };

    const downloadDocument = async (docId, docName) => {
        rotateDoc(0);
        if (docName) {
            if (state.selectedOcrDocId !== docId || state.selectedOcrDocName !== docName) {
                setLoadedStamps([]);
                getDocumentHistory(state.jobId, docId).then((res) => {
                    if (res.success) {
                        let rec = res.data;
                        updateState({ selectedOcrDocHistoryCount: rec.length });
                    }
                });

                const pullDocumentWithPage = async (name, pageNo = 1) => {
                    return getDocumentByName(name, pageNo).then((res) => {
                        addDocumentImagesData(docId, docName, res.imgBase64Src);
                        return res.hasMorePage && pullDocumentWithPage(name, pageNo + 1);
                    });
                };

                const isPdf = docName.toLowerCase().trim().endsWith('.pdf');
                updateState({ loadingDoc: true, isSelectedFilePdf: isPdf, imagesFromUrl: [], currentVisibleDocName: docName });
                pullDocumentWithPage(docName).finally(() => {
                    updateState({ loadingDoc: false });
                });

                getDocumentStamps(state.jobId, docId, docName).then((res) => {
                    const { success, data } = res;
                    if (success) {
                        const allSavedStamps = data?.res?.allStamps;
                        setLoadedStamps(allSavedStamps);
                    }
                });
            }
        } else {
            updateState({ isSelectedFilePdf: false, imagesFromUrl: [] });
        }
    };
    const showDoc = !state.tabView || tabSize > 1260;
    const showStep = state.tabView || tabSize > 1260;

    const showAlldocState = (showdoc, id) => {
        updateState({ tabView: false });
        downloadDocument(showdoc, id);
    };

    const handleDocumentAction = (e) => {
        const { eventType, doc, ocrDocumentId, step } = e;
        switch (eventType) {
            case 'VIEW':
                downloadDocument(ocrDocumentId, doc.docName);
                break;
            case 'MAIL':
                showMail({ ocrDocumentName: step.ocrDocumentName, docName: doc.docName, ocrDocIdToMail: ocrDocumentId });
                break;
            default:
                showToast(`Event Type: ${eventType} is not implement yet.`);
        }
    };
    const HandelMailDocument = (e) => {
        showMail({ ocrDocumentName: e.ocrDocName, docName: e.docName, ocrDocIdToMail: e.ocrDocId });
    };

    const displayDoc = () => {
        if (state.imagesFromUrl.length === 0) {
            return <img src={doc} alt="No document selected" className="sample-doc" />;
        } else {
            let width = docContainer?.current?.clientWidth;
            width = parseInt(width) * zoomFactor;
            let styleObj = {};
            if (documentRotateBy === 0 || documentRotateBy === 180) {
                styleObj.width = `calc(${width}px - 6px)`;
            } else {
                styleObj.height = `calc(${width}px - 6px)`;
            }
            return state.imagesFromUrl.map((imgBase64, i) => (
                <img key={i} alt="Document's" src={imgBase64} className={`shown-doc deg-${documentRotateBy}`} style={styleObj} />
            ));
        }
    };

    const saveWorkflowData = async (markComplete) => {
        const res = await tryToSaveWorkflowData(markComplete);
        showToast(res.message);
    };

    useEffect(() => {
        if (!state.currentVisibleDocName) {
            setTimeout(() => {
                state.workflowSteps.find((st) => {
                    const ocrDoc = getDocumentFromOcrDocId(st.ocrDocumentId);
                    if (ocrDoc && ocrDoc.docName) {
                        handleDocumentAction({
                            eventType: 'VIEW',
                            stepId: st.stepId,
                            ocrDocumentId: st.ocrDocumentId,
                            doc: ocrDoc,
                            st
                        });
                        return true;
                    } else return false;
                });
            }, 1000);
        }
    }, [state.workflowSteps]);

    const NumberWithPound = (params) => {
        const val = +params;
        return <>{val ? <>£{val.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}</> : <>£0.00</>}</>;
    };
    const isTechnician = userRoles.includes('technician');
    const isInternal = userRoles.includes('internal');
    const isCustomer = userRoles.includes('customer');
    const isAuditor = userRoles.includes('auditor');
    const haveSomethingToSave = stampState.stamps.some((c) => c.isNew);

    const hideLeftButtonController = () => {
        const axButtons = ['Notes', 'History', 'Save', 'SaveAndComplete', 'Return', 'ReturnReasonHistory', 'AllDocuments'];
        let shouldHide = true;
        axButtons.map((axB) => {
            if (shouldShowButton(axB)) {
                shouldHide = false;
            }
        });
        return shouldHide;
    };

    const shouldShowButton = (buttonName) => {
        if (isInternal || isTechnician) {
            return true;
        }

        switch (buttonName) {
            case 'Notes':
                return (isCustomer && portalSettings.canCustomerViewNotesButton) || (isAuditor && portalSettings.canAuditorViewNotesButton);
            case 'History':
                return (isCustomer && portalSettings.canCustomerViewHistoryButton) || (isAuditor && portalSettings.canAuditorViewHistoryButton);
            case 'Save':
                return (isCustomer && portalSettings.canCustomerViewSaveButton) || (isAuditor && portalSettings.canAuditorViewSaveButton);
            case 'SaveAndComplete':
                return (isCustomer && portalSettings.canCustomerViewSaveAndCompleteButton) || (isAuditor && portalSettings.canAuditorViewSaveAndCompleteButton);
            case 'Return':
                return (isCustomer && portalSettings.canCustomerViewReturnButton) || (isAuditor && portalSettings.canAuditorViewReturnButton);
            case 'ReturnReasonHistory':
                return (
                    (isCustomer && portalSettings.canCustomerViewReturnReasonHistoryButton) ||
                    (isAuditor && portalSettings.canAuditorViewReturnReasonHistoryButton)
                );
            case 'AllDocuments':
                return (isCustomer && portalSettings.canCustomerViewAllDocumentsButton) || (isAuditor && portalSettings.canAuditorViewAllDocumentsButton);
            default:
                return false;
        }
    };

    const [showConfirmation, setShowConfirmation] = useState(false);
    const checkAndStopMarkingMode = () => {
        if (haveSomethingToSave) {
            setShowConfirmation(true);
        } else {
            stopMarkingMode();
        }
    };

    const getScreenOffset = (isFlag) => {
        if (hideLeftButtonController()) {
            if (isTopNav) {
                return '210px';
            } else {
                return '137px';
            }
        } else {
            if (isTopNav) {
                return '256px';
            } else {
                return '185px';
            }
        }
    };

    const addActionLogHeaderComp = (ModalTitle) => {
        return (
            <Grid container>
                <Grid item>
                    <span>
                        {ModalTitle}
                        <Chip
                            style={{ marginLeft: 10 }}
                            avatar={
                                <Avatar>
                                    <BuildIcon />
                                </Avatar>
                            }
                            label="History"
                            onClick={handleHistoryModal}
                        />
                    </span>
                </Grid>
            </Grid>
        );
    };

    let history = useHistory();
    let mainWorkflowID = history.location.pathname.split('/')[3];

    return (
        <div>
            <BasicButtonGroup
                margin={6}
                className="workflow-buttons"
                selectedId={state.selectedWorkflowId}
                triggerButtonClickOnLoad={false}
                buttons={state.workflows}
                onClick={workflowButtonClicked}
            />

            <div className="workflow-job-details">
                <div className="job-basic-details-container"
                    style={{
                        backgroundColor: mainWorkflowID == 7 ? '#5a5a5a' : 'white',
                        color: mainWorkflowID == 7 ? 'white' : 'black',
                    }}
                >
                    <div className="job-basic-details">
                        {/* <span className="backBtn"> */}
                        <Tooltip title="Back">
                            <IconButton
                                style={{ padding: 0 }}
                                component="span"
                                color="Secondary"
                                onClick={() => {
                                    if (isInternal) {
                                        props.history.push(`/dashgrid`);
                                    } else if (isTechnician) {
                                        props.history.push(`/`);
                                    } else {
                                        props.history.goBack();
                                    }
                                }}
                            >
                                <ArrowBack />
                            </IconButton>
                        </Tooltip>
                        {/* </span> */}
                        {!hideLeftButtonController() && (
                            <Tooltip title="Bar Code">
                                <IconButton component="span" onClick={onBarCodeClick} color="Secondary" style={{ marginLeft: '5px', padding: 0 }}>
                                    <ViewWeekIcon size="small" />
                                </IconButton>
                            </Tooltip>
                        )}
                        {isCommunicatorAllowed && (
                            <Tooltip title="Vehicle Status">
                                <IconButton component="span" onClick={onVehicleStatusClick} color="Secondary" style={{ marginLeft: '5px', padding: 0 }}>
                                    <UpdateIcon size="small" />
                                </IconButton>
                            </Tooltip>
                        )}
                        {(shouldShowButton('Return') && mainWorkflowID == 7) && (
                            <Tooltip title="Return From Archived">
                                <IconButton component="span" onClick={showReturnReason} color="Secondary" style={{ marginLeft: '5px', padding: 0 }}>
                                    <Reply size="small" />
                                </IconButton>
                            </Tooltip>
                        )}
                        {hideLeftButtonController() && (
                            <DefaultButton
                                style={{ padding: '3px 11px', minWidth: '45px', marginRight: '5px' }}
                                title="All Documents"
                                disabled={workflowData.workflowDocs.length === 0}
                                onClick={showAllDoc}
                            >
                                <PhotoLibraryOutlined />
                            </DefaultButton>
                        )}
                        {workflowData.jobBasicDetail && (
                            <div className={`${workflowData.jobBasicDetail.stopFlag && 'set-background-red'}`}>
                                <div>
                                    WIP No:&nbsp; <span>{workflowData.jobBasicDetail.wipNumber}</span>
                                </div>
                                {WindowWidths > 800 && (
                                    <div>
                                        Customer Order No:&nbsp; <span>{workflowData.jobBasicDetail.customerOrderNo}</span>
                                    </div>
                                )}
                                <div>
                                    Reg No:&nbsp;
                                    <span>
                                        {workflowData.jobBasicDetail.regNo}
                                        <span>( {workflowData.jobBasicDetail.vehicleType} )</span>
                                    </span>
                                </div>
                                {WindowWidths > 800 && (
                                    <React.Fragment>
                                        {!isCustomer && (
                                            <div>
                                                {workflowData.jobBasicDetail.customerInternalNotes ? (
                                                    <>
                                                        Customer:&nbsp;
                                                        <span onClick={() => handleCustomerNotes(true)} style={{ color: 'red', cursor: 'pointer' }}>
                                                            {workflowData.jobBasicDetail.customerName}
                                                        </span>
                                                    </>
                                                ) : (
                                                    <>
                                                        Customer:&nbsp;<span>{workflowData.jobBasicDetail.customerName}</span>
                                                    </>
                                                )}
                                            </div>
                                        )}
                                        {!isCustomer && (
                                            <div>
                                                Key Number:&nbsp; <span>{workflowData.jobBasicDetail.keyNumber}</span>
                                            </div>
                                        )}
                                        {!isTechnician && !isCustomer && (
                                            <React.Fragment>
                                                <div>
                                                    Outstanding Payment:&nbsp; <span>{NumberWithPound(workflowData.jobBasicDetail.outstandingPayment)}</span>
                                                </div>
                                                <div>
                                                    Credit Limit:&nbsp; <span>{NumberWithPound(workflowData.jobBasicDetail.creditLimit)}</span>
                                                </div>
                                            </React.Fragment>
                                        )}
                                    </React.Fragment>
                                )}

                                {workflowData.jobBasicDetail.cashMessage && <div className="cash-message">{workflowData.jobBasicDetail.cashMessage}</div>}
                            </div>
                        )}
                    </div>
                    {WindowWidths < 800 && <InfoIcon className="grid-info-icon" onClick={showHeaderDetailsHandler} />}
                </div>
                <Grid container className="grid">
                    {showStep && (
                        <Grid item className="grid-left-section">
                            <div className="steps-container custom-scroll" style={{ height: `calc(100vh - ${getScreenOffset(true)})` }}>
                                {state.workflowSteps.map((s) => (
                                    <WorkflowStep
                                        key={s.stepId}
                                        step={s}
                                        jobId={state.jobId}
                                        technicianJobId={state.technicianJobId}
                                        dropdownValue={workflowData.tempMergedData[s.jsonPropertyName]}
                                        onDropdownChange={dropDownValueSelected}
                                        onDocumentAction={handleDocumentAction}
                                        isTechReadonly={state.isTechReadonly}
                                    />
                                ))}
                            </div>
                            {!hideLeftButtonController() && (
                                <div className="action-buttons">
                                    {shouldShowButton('Notes') && (
                                        <DefaultButton title="Notes" onClick={showNotes} className={workflowData.hasNotes ? 'has-records' : ''}>
                                            <SpeakerNotes />
                                        </DefaultButton>
                                    )}
                                    {shouldShowButton('History') && (
                                        <DefaultButton title="History" onClick={showHistory} className="has-records">
                                            <Timeline />
                                        </DefaultButton>
                                    )}
                                    {shouldShowButton('Save') && (
                                        <PrimaryButton
                                            title="Save"
                                            onClick={() => saveWorkflowData(false)}
                                            disabled={!workflowData.hasSomethingToSave || screenState.isReadonly}
                                        >
                                            <Done />
                                        </PrimaryButton>
                                    )}
                                    {shouldShowButton('SaveAndComplete') && (
                                        <PrimaryButton title="Save and Complete" disabled={!saveBtnEnabled} onClick={() => saveWorkflowData(true)}>
                                            <DoneAll />
                                        </PrimaryButton>
                                    )}
                                    {shouldShowButton('Return') && (
                                        <SecondaryButton title="Return" disabled={screenState.isReadonly} onClick={showReturnReason}>
                                            <Reply />
                                        </SecondaryButton>
                                    )}
                                    {shouldShowButton('ReturnReasonHistory') && (
                                        <DefaultButton title="Return Reason History" onClick={showReturnReasonHistory} className="has-records">
                                            <HistoryIcon />
                                        </DefaultButton>
                                    )}
                                    {shouldShowButton('AllDocuments') && (
                                        <DefaultButton title="All Documents" disabled={workflowData.workflowDocs.length === 0} onClick={showAllDoc}>
                                            <PhotoLibraryOutlined />
                                        </DefaultButton>
                                    )}
                                    {/* <DefaultButton title="Log History">
                                    <AssignmentIcon />
                                </DefaultButton> */}
                                </div>
                            )}
                        </Grid>
                    )}
                    {showDoc && (
                        <Grid item className="grid-right-section dialog-custom">
                            {tabView && (
                                <div className="document-close-action">
                                    <Tooltip title="Close">
                                        <IconButton
                                            component="span"
                                            onClick={() => {
                                                updateState({ tabView: true });
                                            }}
                                        >
                                            <Close />
                                        </IconButton>
                                    </Tooltip>
                                </div>
                            )}
                            {state.imagesFromUrl.length > 0 && (
                                <div className="document-action">
                                    {/* <Tooltip title="Rotate by 90'">
                                            <IconButton component="span" onClick={() => rotateDoc()}>
                                                <Rotate90DegreesCcw />
                                            </IconButton>
                                        </Tooltip> */}
                                    {stampState.isStart ? (
                                        <Grid container direction="column" className="marking-stuff">
                                            <Grid item>
                                                <ToggleButtonGroup
                                                    fullWidth
                                                    className="marking-toolbar"
                                                    color="secondary"
                                                    value={stampState.markingType}
                                                    exclusive
                                                    size="small"
                                                    onChange={markingTypeSelection}
                                                >
                                                    <ToggleButton value={MarkingType.Text}>
                                                        <TextFields fontSize="small" />
                                                    </ToggleButton>
                                                    <ToggleButton value={MarkingType.Sign}>
                                                        <BorderColor fontSize="small" />
                                                    </ToggleButton>
                                                    <ToggleButton value={MarkingType.Calendar}>
                                                        <CalendarToday fontSize="small" />
                                                    </ToggleButton>
                                                    <ToggleButton value={MarkingType.Stamp}>
                                                        <Explicit />
                                                    </ToggleButton>
                                                    <ToggleButton value={MarkingType.Check}>
                                                        <img alt="Check" src={FixedImage.Check} />
                                                    </ToggleButton>
                                                    <ToggleButton value={MarkingType.Cross}>
                                                        <img alt="Cross" src={FixedImage.Cross} />
                                                    </ToggleButton>
                                                    <ToggleButton value={MarkingType.NA}>
                                                        <img alt="NA" src={FixedImage.NA} />
                                                    </ToggleButton>
                                                </ToggleButtonGroup>
                                            </Grid>
                                            <Grid item>
                                                <ButtonGroup fullWidth className="marking-action" size="small">
                                                    <PrimaryButton variant="contained" disabled={!haveSomethingToSave} onClick={saveStampData}>
                                                        Save
                                                    </PrimaryButton>
                                                    <SecondaryButton variant="contained" onClick={checkAndStopMarkingMode}>
                                                        Close
                                                    </SecondaryButton>
                                                </ButtonGroup>
                                                {showConfirmation && (
                                                    <HavePendingMarking
                                                        showConfirmation={showConfirmation}
                                                        removeAndClose={stopMarkingMode}
                                                        handleClose={() => setShowConfirmation(false)}
                                                    />
                                                )}
                                            </Grid>
                                        </Grid>
                                    ) : (
                                        <>
                                            <Tooltip title="History of the Document">
                                                <IconButton component="span" onClick={showOcrDocHistoryModal}>
                                                    <Badge
                                                        className="red-on-white"
                                                        anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
                                                        badgeContent={state.selectedOcrDocHistoryCount}
                                                        color="primary"
                                                    >
                                                        <History />
                                                    </Badge>
                                                </IconButton>
                                            </Tooltip>
                                            <Tooltip title="Stamps and Marking">
                                                <IconButton
                                                    component="span"
                                                    onClick={startMarkingMode}
                                                    color="default"
                                                    className={`${stampState.isStart ? 'stamp-start' : ''}`}
                                                >
                                                    <AlternateEmailOutlined />
                                                </IconButton>
                                            </Tooltip>
                                        </>
                                    )}
                                </div>
                            )}
                            <div
                                className={`document-viewer custom-scroll${state.isSelectedFilePdf && state.imagesFromUrl.length ? ' multi-image-from-pdf' : ''
                                    }${stampState.markingType ? ' marking-start' : ''}`}
                                style={{ height: `calc(100vh - ${getScreenOffset(false)})` }}
                                ref={docContainer}
                            >
                                <div ref={stampContainer}>
                                    {state.loadingDoc ? <LinearProgress /> : <DocStamps docContainer={stampContainer} />}
                                    {displayDoc()}
                                </div>
                            </div>
                        </Grid>
                    )}
                </Grid>

                {screenState.showBarCode && (
                    <DialogComp title={`Bar Code - ${workflowData.jobBasicDetail.wipNumber}`} onClose={onBarCodeClose} fullWidth maxWidth="sm">
                        <ShowBarCode wipNo={workflowData.jobBasicDetail.wipNumber} />
                    </DialogComp>
                )}
                {screenState.showNotesModal && <JobNotesModal jobId={state.jobId} onClose={hideNotesModal} onNotesAdded={notesAdded} />}
                {screenState.showHistoryModal && <WorkflowMovementModal jobId={state.jobId} onClose={hideHistoryModal} />}
                {screenState.showReturnReasonHistoryModal && <ReturnReasonHistoryModal jobId={state.jobId} onClose={hideReturnReasonHistory} />}
                {screenState.showReturnReasonModal && (
                    <ReturnReasonWorkflow
                        workflowId={mainWorkflowID == 7 ? 7 : state.selectedWorkflowId}
                        jobId={state.jobId}
                        onClose={hideReturnReason}
                        showToast={showToast}
                    />
                )}
                {screenState.showOcrDocHistory && (
                    <OcrDocHistory
                        jobId={state.jobId}
                        ocrDocId={state.selectedOcrDocId}
                        showDoc={downloadDocument}
                        onClose={hideOcrDocHistoryModal}
                        showToast={showToast}
                    />
                )}
                {screenState.showMailModal && (
                    <MailDocModal
                        jobId={state.jobId}
                        onClose={hideMail}
                        docInfoToMail={docInfoToMail}
                        jobBasicDetail={workflowData.jobBasicDetail}
                        showToast={showToast}
                    />
                )}
                {screenState.showHeaderDetails && (
                    <DialogComp title="Job Details" maxWidth="md" onClose={() => closeHeaderDetails(false)} fullWidth>
                        <HeaderDetailsModal jobBasicDetail={workflowData.jobBasicDetail} handleCustomerNotes={handleCustomerNotes} />
                    </DialogComp>
                )}
                {screenState.showAllDocModal && (
                    <AllDocModal allDoc={workflowData.workflowDocs} showDoc={showAlldocState} onClose={hideAllDoc} HandelMailDocument={HandelMailDocument} />
                )}
                {screenState.showWarning && <WarningMsg onClose={hideWarningMsg} />}
                {screenState.showCustomerNotes && (
                    <DialogComp title="Customer Notes" maxWidth="md" onClose={() => handleCustomerNotes(false)} fullWidth>
                        <CustomerNotes
                            customerName={workflowData.jobBasicDetail.customerName}
                            customerNote={workflowData.jobBasicDetail?.customerInternalNotes}
                        />
                    </DialogComp>
                )}
                {screenState.siteVehicle ? (
                    // <DialogComp title={addActionLogHeaderComp('Vehicle Status')} onClose={() => handleVehicleStatusClose(false)} fullWidth maxWidth="lg">
                    <AddEditSiteVehicle
                        onClose={handleVehicleStatusClose}
                        headerRecordID={workflowData?.jobBasicDetail?.jobId}
                        reg={workflowData?.jobBasicDetail?.regNo}
                        wip={workflowData?.jobBasicDetail?.wipNumber}
                        isShowHistory={true}
                    />
                ) : // </DialogComp>
                    null}
                {screenState.history ? (
                    <LogHistory
                        headerRecordID={workflowData?.jobBasicDetail?.jobId}
                        reg={workflowData?.jobBasicDetail?.regNo}
                        wip={workflowData?.jobBasicDetail?.wipNumber}
                        onClose={() => closeHistoryModal(false)}
                        hideAddActionBtn={true}
                    />
                ) : null}
            </div>
        </div>
    );
};

const Screen = (props) => {
    return (
        <StateProvider {...props}>
            <WorkflowJobDetails {...props} />
        </StateProvider>
    );
};

export default Screen;
