import React, { useContext, useEffect, useMemo, useState } from 'react';
import ReportGijgoGrid from './../../ReportsScreens/GijgoForReportsScreen/index';
import formatters from './../../../../Core/Grid/inputFormatter';
import { SquareButton } from '../../../../Core/FormInput/AppButton';
// import { EditIcon } from '@material-ui/icons/Edit';
import { Grid } from '@material-ui/core';
import ReactDOM from 'react-dom';
import MessageIcon from '@material-ui/icons/Message';
import EditIcon from '@material-ui/icons/Edit';
import GetAppIcon from '@material-ui/icons/GetApp';
// import {getInvoicePdf} from ''
import { getInvoicePdf, sendInvoice } from './../../../../Core/Service/internal_Invoice-service';
import InvoiceMessage from './invoiceMessage';
import DialogComp from './../../../../Core/Controls/Dialog/DialogComp';
import EditInvoice from './editInvoice';
import SendInvoices from './sendInvoices';
import EmailIcon from '@material-ui/icons/Email';
import BreadCrumbs from '../../../../Core/Controls/Breadcrumb';
import AppContext from '../../../../App/AppContext';
import { AppStorage } from '../../../../Core/Service/storage-service';

var $ = window.$;

const baseCol = [
    { title: 'Action', sortable: true, field: 'action', width: 126, filterable: false },
    { title: 'Invoice No', field: 'invoiceCreditNo001', width: 95, sortable: true },
    {
        title: 'Net',
        field: 'netValue',
        width: 76,
        isCurrency: true,
        filterable: true,
        sortable: true,
        cssClass: 'text-right'
    },
    {
        title: 'Gross',
        field: 'grossValue',
        isCurrency: true,
        width: 76,
        filterable: true,
        sortable: true
    },
    {
        title: 'Status',
        field: 'invoiceStatus',
        width: 150,
        filterable: true,
        sortable: true
    },
    { title: 'Date', field: 'postingDate', width: 100, sortable: true, cssClass: 'text-right', renderer: formatters.MonthShortFormatter },
    { title: 'WIP No', field: 'wipNumber', width: 90, sortable: true },
    { title: 'Branch', field: 'branchName', width: 200, sortable: true },
    { title: 'Customer', field: 'customerName', width: 280, sortable: true }
];

const crumbsList = {
    1: 'Invoices Outstanding',
    2: 'Invoices Queried',
    3: 'Invoices Paid',
    '-1': 'Messages Received',
    '-2': 'Messages Sent'
};

const getCrumbs = (val, menuPermissionCounter) => {
    let list = [{ name: 'Home', path: '/' }];
    if (menuPermissionCounter !== 1) {
        list.push({ name: 'Invoices', path: '/invoice/dashboard' });
    }
    if (val) {
        list.push({ name: crumbsList[val], active: true });
    } else {
        list.push({ name: 'All Invoices', active: true });
    }
    return list;
};

const InvoiceScreens = (props) => {
    let menuPermissionCounter = AppStorage.menuPermissionCounter();
    const { userRoles } = useContext(AppContext);
    const isCustomer = userRoles.includes('customer');
    const [state, setState] = useState({
        showInvoiceMessage: false,
        showEditInvoice: false,
        showSendInvoice: false,
        invoiceIDList: []
    });

    const onButtonClick = async (val) => {
        let res = await getInvoicePdf(val.invoiceFileName);
        const url = window.URL.createObjectURL(new Blob([res], { type: 'application/pdf; charset=utf-8' }));
        let alink = document.createElement('a');
        alink.href = url;
        alink.download = `${val.invoiceFileName}`;
        alink.click();
        alink.target = '_parent';
        alink.remove();
    };

    const handleMessage = (val) => {
        setState((st) => ({
            ...st,
            showInvoiceMessage: true,
            invoice: val
        }));
    };

    const handleEditInvoice = (val) => {
        setState((st) => ({
            ...st,
            showEditInvoice: true,
            invoiceID: val.invoiceId,
            invoiceNo: val.invoiceCreditNo001
        }));
    };

    const actioncolFields = (value, record) => {
        const spn = document.createElement('span');
        const lnk = (
            <Grid container spacing={1} alignItems="center">
                <Grid item>
                    <SquareButton
                        style={{
                            backgroundColor: record.btnTitle === 'Send Message' ? '#28a745' : record.btnTitle === 'Send Reply' ? '#dc3545' : '#183B68',
                            borderRadius: '20%',
                            width: '32px',
                            height: '32px',
                            textAlign: 'center',
                            padding: '0px 0px 0px 0px'
                        }}
                        onClick={() => handleMessage(record)}
                    >
                        <MessageIcon fontSize="small" style={{ color: 'white' }} />
                    </SquareButton>
                </Grid>
                <Grid item>
                    <SquareButton
                        style={{
                            backgroundColor: '#183B68',
                            borderRadius: '20%',
                            width: '32px',
                            height: '32px',
                            textAlign: 'center',
                            padding: '0px 0px 0px 0px'
                        }}
                        onClick={() => handleEditInvoice(record)}
                    >
                        <EditIcon fontSize="small" style={{ color: 'white' }} />
                    </SquareButton>
                </Grid>
                <Grid item>
                    <SquareButton
                        style={{
                            backgroundColor: '#183B68',
                            borderRadius: '20%',
                            width: '32px',
                            height: '32px',
                            textAlign: 'center',
                            padding: '0px 0px 0px 0px'
                        }}
                        onClick={() => onButtonClick(record)}
                    >
                        <GetAppIcon fontSize="small" style={{ color: 'white' }} />
                    </SquareButton>
                </Grid>
            </Grid>
        );
        ReactDOM.render(lnk, spn);
        return spn;
    };

    const getVal = async (gridRef, recList) => {
        var val = [];
        $(`${gridRef} tbody :checkbox:checked`).each(function (i) {
            val[i] = $(this).val();
        });

        let newList = val.map((m, i) => {
            let getValues = m - 1;
            return recList[getValues]?.invoiceId;
        });

        setState((st) => ({
            ...st,
            showSendInvoice: true,
            invoiceID: val.invoiceId,
            invoiceIDList: newList
        }));
    };

    const mailButton = (gridRef, valueList) => {
        const spn = document.createElement('span');
        const lnk = (
            <Grid container justifyContent="center" style={{ paddingRight: '7px' }}>
                <SquareButton
                    style={{
                        backgroundColor: '#183B68',
                        borderRadius: '20%',
                        width: '32px',
                        height: '32px',
                        textAlign: 'center',
                        padding: '0px 0px 0px 0px'
                    }}
                    onClick={() => getVal(gridRef, valueList)}
                >
                    <EmailIcon fontSize="small" style={{ color: 'white' }} />
                </SquareButton>
            </Grid>
        );
        ReactDOM.render(lnk, spn);
        return spn;
    };
    const column = useMemo(() => {
        let newCol = [...baseCol];
        let actionCol = newCol.find((n) => n.field === 'action');
        if (actionCol) {
            actionCol.renderer = actioncolFields;
        }
        return newCol;
    }, []);

    let baseUrl = '';
    if (isCustomer) {
        baseUrl = `Invoices/GetCustomerUserInvoiceList?invoiceStatusID=${props.status || ''}`;
    } else {
        baseUrl = `Invoices?invoiceStatusID=${props.status || ''}`;
    }
    return (
        <div className="report-screen-container">
            <BreadCrumbs crumbs={getCrumbs(props.status, menuPermissionCounter)} />
            <ReportGijgoGrid
                dataConverter={(res) => ({
                    records: res.data.list,
                    total: res.data.total
                })}
                columns={column}
                selectionType="multiple"
                selectionMethod="checkbox"
                getUrl={baseUrl}
                isReload={state.isReload}
                isShowTotal={false}
                hidePagination={false}
                hideFilterRow={false}
                showCheckboxColFilterBtn={mailButton}
            />
            {state.showInvoiceMessage ? (
                <DialogComp
                    title={`Message (${state.invoice.invoiceCreditNo001})`}
                    maxWidth="md"
                    onClose={() =>
                        setState((st) => ({
                            ...st,
                            showInvoiceMessage: false
                        }))
                    }
                    fullWidth
                >
                    <InvoiceMessage
                        onClose={() =>
                            setState((st) => ({
                                ...st,
                                showInvoiceMessage: false,
                                isReload: new Date()
                            }))
                        }
                        invoice={state.invoice}
                    />
                </DialogComp>
            ) : null}
            {state.showEditInvoice ? (
                <DialogComp
                    title={`Edit Invoice`}
                    maxWidth="lg"
                    onClose={() =>
                        setState((st) => ({
                            ...st,
                            showEditInvoice: false
                        }))
                    }
                    fullWidth
                >
                    <EditInvoice
                        onClose={() =>
                            setState((st) => ({
                                ...st,
                                showEditInvoice: false,
                                isReload: new Date()
                            }))
                        }
                        invoiceID={state.invoiceID}
                        invoiceNo={state.invoiceNo}
                    />
                </DialogComp>
            ) : null}
            {state.showSendInvoice ? (
                <DialogComp
                    title={`Send Invoices`}
                    maxWidth="md"
                    onClose={() =>
                        setState((st) => ({
                            ...st,
                            showSendInvoice: false
                        }))
                    }
                    fullWidth
                >
                    <SendInvoices
                        onClose={() =>
                            setState((st) => ({
                                ...st,
                                showSendInvoice: false,
                                isReload: new Date()
                            }))
                        }
                        invoiceIDList={state.invoiceIDList}
                    />
                </DialogComp>
            ) : null}
        </div>
    );
};

export default InvoiceScreens;
