import React, { useContext, useEffect, useState } from "react";
import AppContext from "../../../App/AppContext";
import { Grid } from "@material-ui/core";
import { FormLabel } from "@material-ui/core";
import { TextBox } from "../../../Core/FormInput";
import { YesNoButton } from "../../../Core/FormInput";
import { SecondaryButton } from "../../../Core/FormInput";
import { addUpdateSpecialEquipment } from "../../../Core/Service/SpecialEquipmentService";
import DialogComp from "../../../Core/Controls/Dialog/DialogComp";
import { getBranches } from "../../../Core/Service/branches-service";
import { Autocomplete } from "@material-ui/lab";
import { TextField } from "@material-ui/core";

const AddEditSpecialEquipment = (props) => {
    const { hideModal, showToast } = useContext(AppContext);
    const [state, setState] = useState({
        SpecialEquipmentID: props.focusedRow ? props.focusedRow.specialEquipmentID : null,
        SpecialEquipmentDescription: props.focusedRow ? props.focusedRow.specialEquipmentDescription : "",
        SpecialEquipmentQuantity: props.focusedRow ? props.focusedRow.specialEquipmentQuantity : 0,
        SpecialEquipmentCode: props.focusedRow ? props.focusedRow.specialEquipmentCode : "",
        SpecialEquipmentIsActive: props.focusedRow ? props.focusedRow.specialEquipmentIsActive : true,

        selectedBranch: null,
        branches: [],

        errors: {},
    });

    const pullBranches = async () => {
        const res = await getBranches();

        if (res.success) {
            setState((st) => ({
                ...st,
                branches: res.data
            }));
        }
    }

    useEffect(() => {
        pullBranches();
    }, []);

    useEffect(() => {
        if (props.focusedRow) {
            state.branches?.map((branch) => {
                if (branch.id == props.focusedRow.specialEquipmentBranchID) {
                    setState((st) => ({
                        ...st,
                        selectedBranch: branch
                    }));
                }
            })
        }
    }, [state.branches]);

    const handleClose = () => {
        props.onClose();
        hideModal();
    };

    const handleFieldChange = (e) => {
        const { name, value } = e.target;
        setState((st) => {
            const nst = { ...st, [name]: value };
            return nst;
        });
    };

    const handleClick = (name, val) => {
        setState((st) => {
            const nst = { ...st, [name]: val };
            return nst;
        });
    }

    const handleAutoChange = (e, value) => {
        setState((st) => {
            let newSt = { ...st };
            newSt.selectedBranch = value;
            return newSt;
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (handleValidation()) {
            const data = {
                SpecialEquipmentID: state.SpecialEquipmentID ? state.SpecialEquipmentID : null,
                SpecialEquipmentDescription: state.SpecialEquipmentDescription,
                SpecialEquipmentQuantity: state.SpecialEquipmentQuantity,
                SpecialEquipmentCode: state.SpecialEquipmentCode,
                SpecialEquipmentIsActive: state.SpecialEquipmentIsActive,
                SpecialEquipmentBranchID: state.selectedBranch.id,
            }

            const res = await addUpdateSpecialEquipment(data);
            if (res.success) {
                showToast(res.data.message);
                handleClose();
            } else {
                showToast(res.message);
            }
        }
    };

    const handleValidation = () => {
        let errors = {};
        let formIsValid = true;

        if (!state.SpecialEquipmentDescription) {
            formIsValid = false;
            errors["SpecialEquipmentDescription"] = "This field is mandatory";
        }
        if (!state.SpecialEquipmentQuantity) {
            formIsValid = false;
            errors["SpecialEquipmentQuantity"] = "This field is mandatory";
        }
        if (!state.SpecialEquipmentCode) {
            formIsValid = false;
            errors["SpecialEquipmentCode"] = "This field is mandatory";
        }
        if (!state.selectedBranch) {
            formIsValid = false;
            errors["selectedBranch"] = "This field is mandatory";
        }

        setState((st) => ({
            ...st,
            errors: errors,
        }));
        return formIsValid;
    };

    return (
        <DialogComp
            title={
                props.focusedRow ? `Edit Special Equipment` : `Add Special Equipment`
            }
            maxWidth="lg"
            onClose={handleClose}
            fullWidth
        >
            <Grid container spacing={2}>
                <Grid item xs={6}>
                    <FormLabel required component="legend">
                        Description
                    </FormLabel>
                    <TextBox
                        style={{ marginTop: 15 }}
                        name="SpecialEquipmentDescription"
                        value={state.SpecialEquipmentDescription}
                        onChange={handleFieldChange}
                    />
                    <span className="mandatoryfields">
                        {state.errors["SpecialEquipmentDescription"]}
                    </span>
                </Grid>

                <Grid item xs={6} >
                    <FormLabel required component="legend" style={{ marginBottom: '5px' }}>
                        Branch
                    </FormLabel>
                    <Autocomplete
                        autoSelect
                        options={state.branches}
                        name="selectedBranch"
                        value={state.selectedBranch}
                        onChange={handleAutoChange}
                        getOptionLabel={(option) =>
                            typeof option === 'string' ? option : `${option.branchShortName}`
                        }
                        renderInput={(params) => <TextField margin="dense" fullWidth {...params} variant="outlined" />}
                    />
                    <span className="mandatoryfields">{state.errors['selectedBranch']}</span>
                </Grid>

                <Grid item xs={12} sm={6}>
                    <FormLabel required component="legend">
                        Code
                    </FormLabel>
                    <TextBox
                        style={{ marginTop: 15 }}
                        name="SpecialEquipmentCode"
                        value={state.SpecialEquipmentCode}
                        onChange={handleFieldChange}
                        disabled={props.focusedRow}
                    />
                    <span className="mandatoryfields">
                        {state.errors["SpecialEquipmentCode"]}
                    </span>
                </Grid>

                <Grid item xs={12} sm={6}>
                    <FormLabel required component="legend">
                        Quantity
                    </FormLabel>
                    <TextBox
                        style={{ marginTop: 15 }}
                        name="SpecialEquipmentQuantity"
                        value={state.SpecialEquipmentQuantity}
                        onChange={handleFieldChange}
                        type="number"
                    />
                    <span className="mandatoryfields">
                        {state.errors["SpecialEquipmentQuantity"]}
                    </span>
                </Grid>

                <Grid xs={12} container item>
                    <Grid item xs={12} style={{ marginBottom: '5px' }}>
                        <FormLabel>Is Active?</FormLabel>
                    </Grid>
                    <YesNoButton
                        state={state.SpecialEquipmentIsActive}
                        name="SpecialEquipmentIsActive"
                        onYesClick={() => handleClick('SpecialEquipmentIsActive', true)}
                        onNoClick={() => handleClick('SpecialEquipmentIsActive', false)}
                    />
                </Grid>

                <Grid item xs={12}>
                    <SecondaryButton className="Submitbtn" onClick={handleSubmit} style={{ marginTop: '15px' }}>
                        {props.focusedRow ? "EDIT" : "ADD"}
                    </SecondaryButton>
                </Grid>
            </Grid>
        </DialogComp>
    );
};

export default AddEditSpecialEquipment;