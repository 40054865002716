import AddEditSiteVehicle from './addEditSiteVehicle';
import { useMemo, useState } from 'react';
import DialogComp from './../../../../Core/Controls/Dialog/DialogComp';
import { DialogTitle, Fab, Grid, Button, Avatar, Chip, IconButton } from '@material-ui/core';
import CommonGijgoGrid from '../../../../Core/Controls/GijgoGrid';
import moment from 'moment';
import BreadCrumbs from '../../../../Core/Controls/Breadcrumb';
import { EditOutlined } from '@material-ui/icons/EditOutlined';
import ReactDOM from 'react-dom';
import { CustomIconButton, SquareButton } from '../../../../Core/FormInput/AppButton';
import EditIcon from '@material-ui/icons/Edit';
import ReportGijgoGrid from '../../ReportsScreens/GijgoForReportsScreen';
import ListAltIcon from '@material-ui/icons/ListAlt';
import JobAllDocs from '../../workflow-job/more/AllDocModal';
import AddEditActionLog from '../OpenActionLogs/addEditActionLog';
import EditActionLog from '../OpenActionLogs/editActionLog';
import AssignmentIcon from '@material-ui/icons/Assignment';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import UpdateIcon from '@material-ui/icons/Update';
import BuildIcon from '@material-ui/icons/Build';

import CommunicatorAddVor from '../addUpdateVOR';
import LogHistory from '../OpenActionLogs/logHistory';
const baseColumns = [
    {
        title: 'Action',
        field: 'action',
        width: 110,
        filterable: false
    },
    { field: 'wipNumber', sortable: true, title: `${'WIP No'}`, width: 80 },
    { field: 'registration', sortable: true, title: `${'Vehicle Reg'}`, width: 120 },
    { field: 'st', sortable: true, title: `${'Job Description'}`, width: 170 },
    { field: 'makeModel', sortable: true, title: `${'Model'}`, width: 200 },
    { field: 'customerName', sortable: true, title: `${'Customer Name'}`, width: 200 },
    // {
    //     field: 'dateDueIn',
    //     sortable: true,
    //     title: `${'Due Date In'}`,
    //     // type: 'date',
    //     type: 'dateTime',
    //     // flex: 0.6
    //     width: 135,
    //     renderer: function (value, record) {
    //         if (record.dateDueIn != null) {
    //             return moment(record.dateDueIn).format('DD-MMM-YYYY HH:mm');
    //         }
    //         return '-';
    //     }
    // },
    // {
    //     field: 'dateDueOut',
    //     sortable: true,
    //     title: `${'Date Due Out'}`,
    //     // type: 'date',
    //     type: 'dateTime',
    //     width: 135,
    //     renderer: function (value, record) {
    //         if (record.dateDueOut != null) {
    //             return moment(record.dateDueOut).format('DD-MMM-YYYY HH:mm');
    //         }
    //         return '-';
    //     }
    // },
    // { field: 'returnReason', sortable: true, title: `${'Return Reason'}`, width: 190 },
    // { field: 'chassisNumber', sortable: true,title: 'Chassis Number',  },
    { field: 'branchShortName', sortable: true, title: `${'Branch'}`, width: 120 },
    { field: 'franchiseName', sortable: true, title: `${'Franchise'}`, width: 160 },
    { field: 'customerWaiting', sortable: true, title: `${'Waiting'}`, width: 80 },

    // { field: 'accountNo', sortable: true, title: `${'Account'}`, width: 80 },
    // { field: 'createdBy', sortable: true, title: `${'Created By'}`, width: 120 },
    // {
    //     field: 'assigned',
    //     sortable: true,
    //     title: `${'Assigned Date'}`,
    //     width: 120,
    //     renderer: function (value, record) {
    //         if (record.assigned != null) {
    //             return moment(record.assigned).format('DD-MMM-YYYY HH:mm');
    //         }
    //         return '-';
    //     }
    // },
    // {
    //     field: 'taken',
    //     sortable: true,
    //     title: `${'Time Taken'}`,
    //     width: 100
    // },
    {
        field: 'status',
        sortable: true,
        title: `${'Status'}`,
        width: 180
    }
];

const screenName = {
    S: 'Site Vehicles',
    V: 'VOR Vehicles',
    W: 'Workshop Vehicles',
    A: 'Jobs Approved',
    VOR: 'VOR Vehicles',
    BOOKED: 'Bookings',
    CHECKIN: 'Checked In',
    WORKSHOP: 'Workshop',
    APARTS: 'Awaiting Parts',
    ATA: 'Awaiting Technical Assistance',
    AUTH: 'Awaiting Authority',
    AFON: 'Awaiting Full Order',
    PE: 'Parts Escalation',
    PA: 'Parts Arrived',
    A: 'Approved by customer',
    SUB: 'Waiting Sub Work',
    WC: 'Work Completed',
    CHECKEDOUT: 'Checked Out',
    QC: 'Awaiting QC',
    ACOLLECTION: 'Awaiting Collection',
    REBOOKING: 'Rebooking',
    COLLECTED: 'Collected'
};
const crumbs = (paramStatus, val) => {
    let tempCrum = [
        { name: 'Home', path: '/' },
        { name: 'Communicator', path: '/communicator_dash' }
    ];
    if (paramStatus) {
        tempCrum.push({ name: `${screenName[paramStatus]}`, active: true });
    } else {
        tempCrum.push({ name: screenName[val], active: true });
    }
    return tempCrum;
};

const SiteVehicles = (props) => {
    const search = window.location.search;
    const params = new URLSearchParams(search);
    let ParamStatus = params.get('status') || '';
    const [show, setShow] = useState({
        siteVehicle: false,
        actionLog: false,
        showVORModal: false
    });
    const [state, setState] = useState({});

    const handleEdit = (val) => {
        setShow((st) => ({ ...st, siteVehicle: true, headerRecordID: val.headerRecordID, reg: val.registration, wip: val.wipNumber }));
    };

    const handleEditLogs = (val) => {
        setShow((st) => ({
            ...st,
            actionLog: true,

            headerRecordID: val.headerRecordID,
            reg: val.registration,
            wip: val.wipNumber
        }));
    };
    const handleVORModal = (val) => {
        setShow((st) => ({
            ...st,
            showVORModal: true,
            headerRecordID: val.headerRecordID,
            reg: val.registration,
            wip: val.wipNumber,
            vorID: val.vorid
        }));
    };

    const editButton = (value, record) => {
        const spn = document.createElement('span');
        const lnk = (
            <Grid container alignItems="center" spacing={1}>
                <Grid item>
                    <CustomIconButton onClick={() => handleEdit(record)} toolTipTitle="Vehicle Status">
                        <UpdateIcon fontSize="medium" style={{ color: record.highPriority ? 'white' : '#183B68' }} />
                    </CustomIconButton>
                </Grid>
                <Grid item>
                    <CustomIconButton onClick={() => handleEditLogs(record)} toolTipTitle="Add Action Log">
                        <ListAltIcon fontSize="medium" style={{ color: record.highPriority ? 'white' : '#183B68' }} />
                    </CustomIconButton>
                </Grid>
                <Grid item>
                    <CustomIconButton onClick={() => handleVORModal(record)} toolTipTitle={`${record.vorid ? 'Update' : 'Add'} VOR`}>
                        <BuildIcon fontSize="medium" style={{ color: record.highPriority ? 'white' : '#183B68' }} />
                    </CustomIconButton>
                </Grid>
            </Grid>
        );
        ReactDOM.render(lnk, spn);
        return spn;
    };

    const column = useMemo(() => {
        let col = [...baseColumns];
        let colAction = col.find((m) => m.field === 'action');

        if (colAction) {
            colAction.renderer = editButton;
        }
        if (props.status == 'A') {
            col.splice(
                2,
                0,
                { field: 'approvedNo', sortable: true, title: `${'Order No'}`, width: 120 },
                {
                    field: 'approvedDate',
                    sortable: true,
                    title: `${'Approved Date'}`,
                    type: 'dateTime',
                    width: 145,
                    renderer: function (value, record) {
                        if (record.approvedDate != null) {
                            return moment(record.approvedDate).format('DD-MMM-YYYY HH:mm');
                        }
                        return '-';
                    }
                }
            );
        } else {
            col.splice(
                6,
                0,
                {
                    field: 'dateDueIn',
                    sortable: true,
                    title: `${'Date Due In'}`,
                    // type: 'date',
                    type: 'dateTime',
                    width: 135,
                    renderer: function (value, record) {
                        if (record.dateDueIn != null) {
                            return moment(record.dateDueIn).format('DD-MMM-YYYY HH:mm');
                        }
                        return '-';
                    }
                },
                {
                    field: 'dateDueOut',
                    sortable: true,
                    title: `${'Date Due Out'}`,
                    // type: 'date',
                    type: 'dateTime',
                    width: 135,
                    renderer: function (value, record) {
                        if (record.dateDueOut != null) {
                            return moment(record.dateDueOut).format('DD-MMM-YYYY HH:mm');
                        }
                        return '-';
                    }
                }
            );
        }

        return col;
    }, []);

    const handleClose = (res) => {
        let newSt = {};
        newSt.MotHistory = false;
        newSt.actionLog = false;
        newSt.showVORModal = false;
        newSt.history = false;

        if (res) {
            newSt.isReload = new Date();
        } else {
            newSt.siteVehicle = false;
        }
        setShow((st) => ({
            ...st,
            ...newSt
        }));
    };

    const handleHistoryModal = (val) => {
        setShow((st) => ({ ...st, history: true }));
    };
    const closeHistoryModal = (val) => {
        setShow((st) => ({ ...st, history: false }));
    };

    const rowDataStyle = (record) => {
        if (record.highPriority) {
            return {
                ['background-color']: '#C4463B'
            };
        }
        return { ['background-color']: '' };
    };
    const colDataStyle = (record) => {
        if (record.highPriority) {
            return {
                ['color']: 'white'
            };
        }
        return {
            ['color']: 'black'
        };
    };

    const addActionLogHeaderComp = (ModalTitle) => {
        return (
            <Grid container>
                <Grid item>
                    <span>
                        {ModalTitle}
                        <Chip
                            style={{ marginLeft: 10 }}
                            avatar={
                                <Avatar>
                                    <BuildIcon />
                                </Avatar>
                            }
                            label="History"
                            onClick={handleHistoryModal}
                        />
                    </span>
                </Grid>
            </Grid>
        );
    };

    const getUrl = `Communicators?ShowJobs=${ParamStatus || props.status}`;

    return (
        <div className="report-screen-container">
            <BreadCrumbs crumbs={crumbs(ParamStatus, props.status)} />
            <Grid container>
                <Grid item xs={12}>
                    <ReportGijgoGrid
                        dataConverter={(res) => ({
                            records: res.data.list,
                            total: res.data.total
                        })}
                        columns={column}
                        getUrl={getUrl}
                        displayPagination={true}
                        isReload={show.isReload}
                        setRowStyleOnCondition={true}
                        setRowStyleFunction={rowDataStyle}
                        setColStyleOnCondition={true}
                        setColStyleFunction={colDataStyle}
                    />
                </Grid>
            </Grid>

            {show.siteVehicle ? (
                // <DialogComp title={addActionLogHeaderComp('Vehicle Status')} onClose={() => handleClose(false)} fullWidth maxWidth="lg">
                <AddEditSiteVehicle onClose={handleClose} headerRecordID={show.headerRecordID} reg={show.reg} wip={show.wip} isShowHistory={true} />
            ) : // </DialogComp>
            null}
            {show.actionLog ? (
                <DialogComp title={addActionLogHeaderComp('Add Action Log')} onClose={() => handleClose(false)} fullWidth maxWidth="lg">
                    <AddEditActionLog onClose={handleClose} headerRecordID={show.headerRecordID} reg={show.reg} wip={show.wip} />
                </DialogComp>
            ) : null}
            {show.showVORModal ? (
                <DialogComp title={`${show.vorID ? 'Update ' : 'Add '}VOR -${show.wip}`} onClose={() => handleClose(false)} fullWidth maxWidth="lg">
                    <CommunicatorAddVor vorID={show.vorID} onClose={handleClose} headerRecordID={show.headerRecordID} reg={show.reg} wip={show.wip} />
                </DialogComp>
            ) : null}
            {show.history ? (
                <LogHistory
                    headerRecordID={show.headerRecordID}
                    reg={show.reg}
                    wip={show.wip}
                    onClose={() => closeHistoryModal(false)}
                    hideAddActionBtn={true}
                />
            ) : null}
        </div>
    );
};
export default SiteVehicles;
