import React, { useContext, useEffect, useState, useMemo } from 'react';
import AppContext from '../../../App/AppContext';
import { Grid } from '@material-ui/core';
import formatters from '../../../Core/Grid/inputFormatter';
import ReportGijgoGrid from '../ReportsScreens/GijgoForReportsScreen';
import { Breadcrumbs } from '@material-ui/core';
import ReactDOM from 'react-dom';
import { Typography } from '@material-ui/core';
import { Link } from '@material-ui/core';

const SpecialEquipmentHistoryModal = (props) => {
    const { hideModal, showToast } = useContext(AppContext);
    const [state, setState] = useState({});

    const handleClose = () => {
        props.onClose();
        hideModal();
    };

    const baseColumns = [
        {
            field: 'specialEquipmentUsedId',
            title: 'ID',
            hidden: true,
            width: '5%'
        },
        {
            field: 'wipNumber',
            title: 'WIP Number',
            width: '5%'
        },
        {
            field: 'branch',
            title: 'Branch',
            width: '5%'
        },
        {
            field: 'regNumber',
            title: 'Reg Number',
            width: '5%'
        },
        {
            field: 'customerName',
            title: 'Customer Name',
            width: '5%'
        },
        {
            field: 'displayNameAllocatedBy',
            title: 'Allocated By',
            width: '5%'
        },
        {
            field: 'specialEquipmentInUse',
            title: 'In Use?',
            hidden: props.activeOnly,
            width: '5%',
            hidden: true
        },
        {
            field: 'specialEquipmentHeaderRecordId',
            title: 'Header Record Id',
            hidden: true,
            width: '5%'
        },
        {
            field: 'workflowID',
            title: 'Workflow ID',
            hidden: true,
            width: '5%'
        },
        {
            field: 'specialEquipmentDateAllocated',
            title: 'Date Allocated',
            width: '5%',
            hidden: true
        },
        {
            field: 'specialEquipmentDateUnallocated',
            title: 'Date De-Allocated',
            hidden: props.activeOnly,
            width: '5%'
        }
    ];

    const allocatedRenderer = (field) => (value, record) => {
        const spn = document.createElement('span');
        const lnk = (
            <div>
                {record.displayNameAllocatedBy}{' '}
                <span style={{ fontStyle: 'italic' }}>on {formatters.DateTimeFormatter(record.specialEquipmentDateAllocated)}</span>
            </div>
        );
        ReactDOM.render(lnk, spn);
        return spn;
    };

    const deallocatedRenderer = (field) => (value, record) => {
        const spn = document.createElement('span');
        const lnk =
            <div>
                {record.specialEquipmentInUse ? (
                    <>In Use</>
                ) : (
                    <>
                        {record.specialEquipmentAllocatedBy}
                        <span style={{ fontStyle: 'italic' }}> on {formatters.DateTimeFormatter(record.specialEquipmentDateUnallocated)}</span>
                    </>
                )}
            </div>;
        ReactDOM.render(lnk, spn);
        return spn;
    };

    const wipField = (field) => (value, record) => {
        const spn = document.createElement('span');
        const lnk = (
            <a style={{ textDecoration: 'none' }} href={`/Job/${record.specialEquipmentHeaderRecordId}/${record.workflowID}`}>
                <span style={{ color: 'red' }}>{record.wipNumber}</span>
            </a>
        );
        ReactDOM.render(lnk, spn);
        return spn;
    };

    const columns = useMemo(() => {
        const tempCols = [...baseColumns];

        let inUseCol = tempCols.find((element) => element.field === 'specialEquipmentInUse');
        let wipColumn = tempCols.find((element) => element.field === 'wipNumber');
        let allocatedByColumn = tempCols.find((element) => element.field === 'displayNameAllocatedBy');
        let deallocatedColumn = tempCols.find((element) => element.field === 'specialEquipmentDateUnallocated');

        if (inUseCol) {
            inUseCol.renderer = (val) => (val ? 'Yes' : 'No');
        }
        if (wipColumn) {
            wipColumn.renderer = wipField({});
        }
        if (allocatedByColumn) {
            allocatedByColumn.renderer = allocatedRenderer({});
        }
        if (deallocatedColumn) {
            deallocatedColumn.renderer = deallocatedRenderer({});
        }
        return tempCols;
    }, []);

    let BaseUrl = () => {
        console.log(props.focusedRow?.specialEquipmentID)
        if(props.focusedRow?.specialEquipmentID) {
            return `SpecialEquipment/SpecialEquipmentUsed/equipment/${props.focusedRow.specialEquipmentID}/${props.activeOnly}`;
        } else {
            return `SpecialEquipment/SpecialEquipmentUsed/all/${props.activeOnly}`;
        }
    }

    return (
        <div>
            <Grid item container justify="space-between">
                <Grid item>
                    <Breadcrumbs separator=">>" aria-label="breadcrumb">
                        <div onClick={() => (window.location = '/')}>
                            <Link to="/" style={{ cursor: 'pointer', textDecoration: 'none' }}>
                                Home
                            </Link>
                        </div>
                        <div onClick={handleClose}>
                            <Link to="/" style={{ cursor: 'pointer', textDecoration: 'none' }}>
                                Special Equipments
                            </Link>
                        </div>
                        {props.focusedRow.specialEquipmentCode && (
                            <div>
                                <Typography color="secondary">{props.focusedRow.specialEquipmentCode}</Typography>
                            </div>
                        )}
                        <div>
                            <Typography color="secondary">{props.activeOnly ? 'In Use' : 'History'}</Typography>
                        </div>
                    </Breadcrumbs>
                </Grid>
            </Grid>

            <Grid item spacing={1} xs={12} style={{}}>
                <Grid item xs={12}>
                    <ReportGijgoGrid
                        dataConverter={(res) => ({
                            records: res?.data.sort((a, b) => {
                                return b.specialEquipmentInUse - a.specialEquipmentInUse;
                            }),
                            total: res?.data?.total
                        })}
                        columns={columns}
                        getUrl={BaseUrl()}
                        isReload={state.isReload}
                    />
                </Grid>
            </Grid>
        </div>
    );
};

export default SpecialEquipmentHistoryModal;
