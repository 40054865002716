import React, { PureComponent } from 'react';
import { BarChart, Bar, Cell, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer, Label, ComposedChart } from 'recharts';
import { LineChart, Line, PieChart, Pie, Sector, LabelList } from 'recharts';
// import { Tooltip } from '@material-ui/core';

const COLORS = [
    '#0074D9',
    '#FF4136',
    '#2ECC40',
    '#FF851B',
    '#7FDBFF',
    '#B10DC9',
    '#FFDC00',
    '#001f3f',
    '#39CCCC',
    '#01FF70',
    '#85144b',
    '#F012BE',
    '#3D9970',
    '#111111',
    '#AAAAAA'
];

const barColors = {
    0: '#0074D9',
    1: '#FF4136',
    2: '#2ECC40',
    3: '#FF851B',
    4: '#7FDBFF',
    5: '#B10DC9',
    6: '#FFDC00',
    7: '#001f3f',
    8: '#39CCCC',
    9: '#01FF70',
    10: '#85144b',
    11: '#F012BE',
    12: '#3D9970',
    13: '#111111',
    14: '#AAAAAA'
};

export const ServicePieChart = (props) => {
    return (
        <>
            {props.data?.length > 0 ? (
                <>
                    <ResponsiveContainer width="100%" height="100%">
                        <PieChart width={400} height={400}>
                            <Pie
                                data={props.data}
                                cx="30%"
                                cy="60%"
                                outerRadius={130}
                                innerRadius={50}
                                fill="#8884d8"
                                dataKey="total"
                                paddingAngle={3}
                                // labelLine={true}
                                // label/
                            >
                                {props.data.map((entry, index) => (
                                    <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                                ))}
                            </Pie>
                        </PieChart>
                        {/* <Tooltip contentStyle={{ background: 'rgba(0,0,0,0.8)', color: 'white', fontSize: 12, fontWeight: 700 }} position={{ x: 30, y: 20 }} /> */}
                    </ResponsiveContainer>
                    <div style={{ position: 'absolute', right: 30, top: 40 }} className="custom-scroll">
                        {props.data.map((m, i) => {
                            let color = COLORS[i];
                            return (
                                <div style={{ padding: 0 }}>
                                    <div style={{ fontSize: 15, width: 150, display: 'flex', justifyContent: 'flex-start' }}>
                                        {' '}
                                        <div style={{ background: color, height: 10, width: 10, marginTop: 4 }}></div>
                                        <div style={{ fontSize: 15 }}>&nbsp;{m.name}</div>
                                        <div style={{ fontSize: 13, fontWeight: 'bold' }}>&nbsp;({m.total})</div>
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                </>
            ) : (
                <div
                    style={{
                        height: '327px',
                        width: '100%',
                        border: '2px solid #F5F5F5',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        marginTop: 25
                    }}
                >
                    No Record found
                </div>
            )}
        </>
    );
};

export const ServieBarChart = (props) => {
    return (
        <>
            {props.data?.length > 0 ? (
                <ResponsiveContainer width="100%" height="100%">
                    <BarChart
                        data={props.data}
                        width={300}
                        margin={{
                            top: 20,
                            right: 5,
                            left: -16,
                            bottom: 15
                        }}
                        contentStyle={{ margingBottom: 10 }}
                    >
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis
                            dataKey="name"
                            style={{ fontSize: 10 }}
                            textAnchor="end"
                            sclaeToFit="true"
                            verticalAnchor="start"
                            interval={0}
                            angle="-40"
                            // stroke="#8884d8"
                        />

                        <YAxis style={{ fontSize: 10 }} />

                        <Tooltip contentStyle={{ background: 'rgba(0,0,0,0.8)', color: 'white', fontSize: 12, fontWeight: 700 }} />
                        <Bar dataKey="total" fill="#8884d8" barSize={20}>
                            {props.data.map((entry, index) => (
                                <Cell
                                    key={`cell-${index}`}
                                    fill={barColors[index]}
                                    onClick={() => {
                                        props.onClick && props.onClick()
                                    }}
                                    style={{ cursor: 'pointer' }}
                                />
                            ))}
                        </Bar>
                    </BarChart>
                </ResponsiveContainer>
            ) : (
                <div
                    style={{
                        height: '327px',
                        width: '100%',
                        border: '2px solid #F5F5F5',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center'
                    }}
                >
                    No Record found
                </div>
            )}
        </>
    );
};

export const ServiceLineChart = (props) => {
    return (
        <>
            {props.data?.length > 0 ? (
                <ResponsiveContainer width="100%" height="100%">
                    <LineChart
                        width={500}
                        height={300}
                        data={props.data}
                        margin={{
                            top: 20,
                            right: 5,
                            left: -16,
                            bottom: 15
                        }}
                    >
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis
                            dataKey="name"
                            style={{ fontSize: 10 }}
                            textAnchor="end"
                            sclaeToFit="true"
                            verticalAnchor="start"
                            interval={0}
                            angle="-40"
                            // stroke="#8884d8"
                        />
                        <YAxis style={{ fontSize: 10 }} />
                        <Tooltip contentStyle={{ background: 'rgba(0,0,0,0.8)', color: 'white', fontSize: 12, fontWeight: 700 }} />
                        {/* <Legend /> */}
                        <Line type="monotone" dataKey="total" stroke="#8884d8" strokeWidth={4} />
                    </LineChart>
                </ResponsiveContainer>
            ) : (
                <div
                    style={{
                        height: '327px',
                        width: '100%',
                        border: '2px solid #F5F5F5',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center'
                    }}
                >
                    No Record found
                </div>
            )}
        </>
    );
};
